import React from 'react';
import {
  useGetAllTimezonesQuery,
  useGetProfileQuery,
  useSetUserCountryTimezoneMutation
} from '../redux/slices/userApiSlice';
import {
  Autocomplete,
  Avatar,
  Chip,
  CircularProgress,
  Box,
  Skeleton,
  Stack,
  TextField,
  Typography,
  darken,
  lighten,
  styled
} from '@mui/material';
import { open } from '../redux/slices/snackbarSlice';
import { useAppDispatch } from '../redux/hooks';
import { CountryTimezone, User } from '../models/basic';

const GroupHeader = styled('div')(({ theme }) => ({
  fontSize: '0.8rem',
  position: 'sticky',
  top: '-8px',
  padding: '4px 10px',
  color: theme.palette.primary.main,
  backgroundColor:
    theme.palette.mode === 'light'
      ? lighten(theme.palette.primary.light, 0.85)
      : darken(theme.palette.primary.main, 0.8)
}));

const GroupItems = styled('ul')({
  padding: 0
});

const ProfileView = () => {
  const dispatch = useAppDispatch();
  const { data: userData } = useGetProfileQuery();
  const { data: timezoneData, isLoading: isTimezoneLoading } = useGetAllTimezonesQuery();
  const [selectedTimezone, setSelectedTimezone] = React.useState<CountryTimezone | null>(null);
  const [timezoneOptions, setTimezoneOptions] = React.useState<CountryTimezone[]>([]);
  const [currentUser, setCurrentUser] = React.useState<User | null>(null);
  const [setUserCountryTimezone, { isLoading: isTimezonePosting }] = useSetUserCountryTimezoneMutation();

  React.useEffect(() => {
    if (timezoneData) {
      setTimezoneOptions(timezoneData);
      // Must set the timezone options before setting the selected timezone to avoid out of bounds error
      if (userData) {
        setSelectedTimezone(userData.country_timezone);
        setCurrentUser(userData);
      }
    }
  }, [userData, timezoneData]);

  const handleChangeTimezone = async (timezone: CountryTimezone) => {
    if (!currentUser) return;

    const user = await setUserCountryTimezone({
      user_id: currentUser.id,
      country_timezone: timezone
    }).unwrap();
    setCurrentUser(user);
    dispatch(
      open({
        open: true,
        message: 'Successfully updated user',
        severity: 'success'
      })
    );
  };

  return (
    <Stack direction="row" justifyContent="center" alignItems="center">
      <Box sx={{ p: 5, minWidth: '300px', width: '500px', border: 1, borderRadius: '5%', borderColor: 'lightgray' }}>
        {!currentUser || !selectedTimezone ? (
          <div className="flex items-center justify-center p-5">
            <CircularProgress size="10rem" />
          </div>
        ) : (
          <Stack justifyContent="center" alignItems="center" spacing={4}>
            <Avatar sx={{ width: 120, height: 120 }}>
              <Typography variant="h4">{currentUser.display_name.at(0)}</Typography>
            </Avatar>
            <Stack direction="row" justifyContent="center" alignItems="flex-end" spacing={4} sx={{ width: '100%' }}>
              <TextField fullWidth label="Name" value={currentUser.display_name} disabled variant="standard" />
              <Chip label={currentUser.permission_level} sx={{ color: 'GrayText' }} />
            </Stack>
            <TextField fullWidth label="Email" value={currentUser.email} disabled variant="standard" />

            {isTimezonePosting ? (
              <Skeleton width={'100%'} animation="wave">
                <TextField fullWidth />
              </Skeleton>
            ) : (
              <TextField
                fullWidth
                label="Country"
                value={currentUser.country_timezone.country_name || ''}
                disabled
                variant="standard"
              />
            )}

            {isTimezonePosting ? (
              <Skeleton width={'100%'} animation="wave">
                <TextField fullWidth />
              </Skeleton>
            ) : (
              <Autocomplete
                freeSolo={false}
                fullWidth
                loading={isTimezoneLoading}
                value={selectedTimezone}
                isOptionEqualToValue={(option, value) => {
                  return option.timezone === value.timezone || (value.timezone === null && option.timezone === '');
                }}
                options={timezoneOptions.concat({
                  country_code: null,
                  country_name: 'Select a Timezone',
                  timezone: ''
                })}
                getOptionDisabled={(option) => {
                  return option.timezone === '';
                }}
                filterOptions={(options, { inputValue }) =>
                  options.filter(
                    (option) =>
                      option.country_name.toLowerCase().includes(inputValue.toLowerCase()) ||
                      option.timezone.toLowerCase().includes(inputValue.toLowerCase())
                  )
                }
                onChange={(event, value) => {
                  if (value) {
                    handleChangeTimezone(value);
                  }
                }}
                groupBy={(option) => option.country_name}
                getOptionLabel={(option) => (option.timezone ? option.timezone : '')}
                renderInput={(params) => <TextField {...params} label="Timezone" variant="standard" />}
                renderGroup={(params) => (
                  <li key={params.key}>
                    <GroupHeader>{params.group}</GroupHeader>
                    <GroupItems>{params.children}</GroupItems>
                  </li>
                )}
              />
            )}
          </Stack>
        )}
      </Box>
    </Stack>
  );
};

export default ProfileView;
