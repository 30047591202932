import { Avatar, Stack, Typography } from '@mui/material';
import * as React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import { UserInTeam } from '../models/basic';

interface ITeamOverviewProps {
  teamMembers: UserInTeam[];
  teamName: string;
  isHorizontalTeam: boolean;
}

interface IAlignItemsList {
  teamMembers: UserInTeam[];
}

/**
 * This component is a list of team members.
 * @param props the props for this component.
 * @returns the AlignItemsList component.
 */
function AlignItemsList(props: IAlignItemsList) {
  const { teamMembers } = props;

  return (
    <List
      sx={{
        width: '100%',
        maxWidth: 560,
        height: '100%',
        overflowY: 'scroll',
        maxHeight: 360,
        bgcolor: 'background.paper'
      }}
    >
      {teamMembers.map((teamMember) => (
        <React.Fragment key={teamMember.id}>
          <ListItem alignItems="flex-start">
            <ListItemAvatar>
              <Avatar>{teamMember.display_name.at(0)}</Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={teamMember.display_name}
              secondary={
                <React.Fragment>
                  <Typography sx={{ display: 'inline' }} component="span" variant="body2" color="text.secondary">
                    {teamMember.experience} {teamMember.role}
                  </Typography>
                  {' - ' + teamMember.email + ' '}
                </React.Fragment>
              }
            />
          </ListItem>
          <Divider variant="inset" component="li" />
        </React.Fragment>
      ))}
    </List>
  );
}

/**
 * This component displays an overview of a team.
 * @param props the props for this component.
 * @returns the TeamOverview component.
 */
export default function TeamOverivew(props: ITeamOverviewProps) {
  const { teamMembers, teamName, isHorizontalTeam } = props;

  return (
    <Stack direction="row" spacing={4} sx={{ p: 3 }}>
      <Stack spacing={3} justifyContent="center" alignItems="center">
        <Avatar sx={{ width: 200, height: 200 }}>B</Avatar>
        <Stack justifyContent="center" alignItems="center">
          <Typography variant="h5">Team {teamName}</Typography>
          <Typography variant="caption">{(isHorizontalTeam ? 'Horizontal' : 'Vertical') + ' Team'}</Typography>
        </Stack>
      </Stack>
      <Stack
        spacing={3}
        justifyContent="center"
        alignItems="center"
        sx={{ borderLeft: 1, borderLeftColor: 'rgba(0, 0, 0, 0.12)' }}
      >
        <Typography variant="h6">Team Members</Typography>
        <AlignItemsList teamMembers={teamMembers} />
      </Stack>
    </Stack>
  );
}
