import {
  Autocomplete,
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  Switch,
  TextField,
  Tooltip,
  Typography
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import React from 'react';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import dayjs from 'dayjs';
import moment from 'moment-timezone';
import 'dayjs/locale/en-gb';
import utc from 'dayjs/plugin/utc';
import { useAddLeaveMutation } from '../redux/slices/leaveApiSlice';
import { useAppDispatch } from '../redux/hooks';
import { open } from '../redux/slices/snackbarSlice';
import { User } from '../models/basic';
import { LeaveCreationBody, LeavesCreationBody } from '../models/leave';

dayjs.locale('en-gb');
dayjs.extend(utc);

interface ILeaveFormProps {
  userOptions: User[];
  onClose: () => void;
}

interface IDay {
  index: number;
  day: string;
}

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const LeaveForm = (props: ILeaveFormProps) => {
  const dispatch = useAppDispatch();
  const { userOptions } = props;
  const [userId, setUserId] = React.useState('');
  const [leaveTimezone, setLeaveTimezone] = React.useState('Pacific/Auckland');
  const [userError, setUserError] = React.useState(false);
  const [leaveType, setLeaveType] = React.useState('');
  const [leaveTypeError, setLeaveTypeError] = React.useState(false);
  const [summary, setSummary] = React.useState('');
  const [summaryError, setSummaryError] = React.useState(false);
  const [startDate, setStartDate] = React.useState<Date | null>(null);
  const [startDateError, setStartDateError] = React.useState(false);
  const [endDate, setEndDate] = React.useState<Date | null>(null);
  const [endDateError, setEndDateError] = React.useState(false);
  const [days, setDays] = React.useState<IDay[]>([]);
  const [daysError, setDaysError] = React.useState(false);
  const [reoccurring, setReoccurring] = React.useState(false);
  const [addLeave] = useAddLeaveMutation();

  const handleChangeUser = (event: SelectChangeEvent) => {
    setUserId(event.target.value as string);
    // Get the user's timezone if it exists
    const user = userOptions.find((user) => user.id === event.target.value);
    if (user?.country_timezone.timezone) {
      setLeaveTimezone(user.country_timezone.timezone);
    } else {
      setLeaveTimezone('Pacific/Auckland');
    }
  };

  const handleChangeLeaveType = (event: SelectChangeEvent) => {
    setLeaveType(event.target.value as string);
  };

  const handleChangeSummary = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSummary(event.target.value);
  };

  const handleSetReoccurring = (event: React.ChangeEvent<HTMLInputElement>) => {
    setReoccurring(event.target.checked);
  };

  const getDaysInRange = (day: IDay, startDate: moment.Moment, endDate: moment.Moment) => {
    // Clone the start and end dates
    startDate = startDate.clone();
    endDate = endDate.clone();

    const days = [];
    let current = startDate; // Start from the beginning of the start date
    while (current.isBefore(endDate) || current.isSame(endDate)) {
      // While the current date is before or equal to the end date
      if (current.day() === day.index) {
        // 1 represents Monday (0 for Sunday, 1 for Monday, ...)
        days.push(current.clone()); // Add the Monday to the list
      }
      current = current.add(1, 'days'); // Move to the next day
    }

    return days;
  };

  const validateForm = () => {
    let error = false;
    setUserError(false);
    setLeaveTypeError(false);
    setSummaryError(false);
    setStartDateError(false);
    setEndDateError(false);
    setDaysError(false);

    if (userId === '') {
      setUserError(true);
      error = true;
    }
    if (leaveType === '') {
      setLeaveTypeError(true);
      error = true;
    }
    if (summary === '') {
      setSummaryError(true);
      error = true;
    }
    if (startDate === null) {
      setStartDateError(true);
      error = true;
    }
    if (endDate === null) {
      setEndDateError(true);
      error = true;
    }
    if (reoccurring && days.length === 0) {
      setDaysError(true);
      error = true;
    }

    return !error;
  };

  const handleSubmit = async () => {
    // Validate form
    if (!validateForm()) {
      return;
    }

    // Redundant ts check
    if (startDate === null || endDate === null) {
      return;
    }

    // Convert dates to moment
    const startDateMoment = moment.tz(moment(startDate.toISOString()).format('YYYY-MM-DD'), leaveTimezone);
    const endDateMoment = moment.tz(moment(endDate.toISOString()).format('YYYY-MM-DD'), leaveTimezone);

    const leaves: LeavesCreationBody = {
      leaves: [],
      user_id: userId
    };

    // If reoccurring, add leaves for each day
    if (reoccurring) {
      for (const day of days) {
        const daysInRange = getDaysInRange(day, startDateMoment, endDateMoment);
        for (const dayInRange of daysInRange) {
          // Create leave object
          const leave: LeaveCreationBody = {
            leave_type: leaveType,
            summary: summary,
            start_date: dayInRange.toISOString(true),
            end_date: dayInRange.add(1, 'day').toISOString(true)
          };
          leaves.leaves.push(leave);
        }
      }
    } else {
      // Create leave object
      const leave: LeaveCreationBody = {
        leave_type: leaveType,
        summary: summary,
        start_date: startDateMoment.toISOString(true),
        end_date: endDateMoment.toISOString(true)
      };

      leaves.leaves.push(leave);
    }

    // Add leave
    await addLeave(leaves).unwrap();
    dispatch(open({ open: true, message: 'Added leave successfully!', severity: 'success' }));
  };

  return (
    <Stack spacing={4} justifyContent="center" alignItems="center">
      <Typography variant="h4">Add Leave</Typography>
      <Stack direction="row" spacing={3} sx={{ width: '100%' }}>
        <Stack spacing={2} sx={{ width: '50%' }}>
          <FormControl fullWidth error={userError}>
            <InputLabel id="user-input-label">User</InputLabel>
            <Select labelId="user-input-label" id="user-input" value={userId} label="User" onChange={handleChangeUser}>
              {userOptions.map((user) => (
                <MenuItem key={user.id} value={user.id}>
                  {user.display_name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl fullWidth error={leaveTypeError}>
            <InputLabel id="leave-type-label">Type</InputLabel>
            <Select
              labelId="leave-type-label"
              id="leave-type"
              value={leaveType}
              label="Type"
              onChange={handleChangeLeaveType}
            >
              <MenuItem value={'Annual'} disabled>
                Annual
              </MenuItem>
              <MenuItem value={'Sick'} disabled>
                Sick
              </MenuItem>
              <MenuItem value={'Other'}>Other</MenuItem>
            </Select>
          </FormControl>
          <TextField
            label="Summary"
            variant="outlined"
            value={summary}
            error={summaryError}
            onChange={handleChangeSummary}
          />
          <FormGroup sx={{ width: '0px' }}>
            <FormControlLabel
              control={<Switch checked={reoccurring} onChange={handleSetReoccurring} />}
              label="Reoccurring"
            />
          </FormGroup>
        </Stack>
        <Divider orientation="vertical" flexItem />
        <Stack spacing={2} sx={{ width: '50%' }}>
          <DatePicker
            label="Start Date"
            value={startDate}
            onChange={(newValue) => setStartDate(newValue)}
            maxDate={endDate}
            slotProps={{
              textField: {
                error: startDateError
              }
            }}
          />
          <DatePicker
            label="End Date"
            value={endDate}
            onChange={(newValue) => setEndDate(newValue)}
            minDate={startDate}
            slotProps={{
              textField: {
                error: endDateError
              }
            }}
          />
          <Autocomplete
            disabled={!reoccurring}
            multiple
            options={[
              { index: 0, day: 'Sunday' },
              { index: 1, day: 'Monday' },
              { index: 2, day: 'Tuesday' },
              { index: 3, day: 'Wednesday' },
              { index: 4, day: 'Thursday' },
              { index: 5, day: 'Friday' },
              { index: 6, day: 'Saturday' }
            ]}
            disableCloseOnSelect
            getOptionLabel={(option) => option.day}
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
                {option.day}
              </li>
            )}
            style={{ width: '100%' }}
            renderInput={(params) => <TextField {...params} label="Days" error={reoccurring && daysError} />}
            onChange={(event, value) => setDays(value)}
            value={days}
            isOptionEqualToValue={(option, value) => {
              return option.index === value.index;
            }}
          />
          <Tooltip title="User's timezone; default to Pacific/Auckland if country is unspecified.">
            <p>
              <b>Timezone:</b> {leaveTimezone}
            </p>
          </Tooltip>
        </Stack>
      </Stack>
      <Stack direction={'row'}>
        <Button variant="contained" sx={{ color: 'white', margin: '0 8px' }} onClick={handleSubmit}>
          Submit
        </Button>    
        <Button variant="contained" color="secondary" sx={{ color: 'white', margin: '0 8px' }} onClick={props.onClose}>
          Close
        </Button>     
      </Stack>

    </Stack>
  );
};

export default LeaveForm;
