import {
  Autocomplete,
  Chip,
  CircularProgress,
  FormControl,
  FormControlLabel,
  Stack,
  Switch,
  TextField,
  Typography
} from '@mui/material';
import { useGetAllUsersQuery } from '../redux/slices/userApiSlice';
import React from 'react';
import { User, UserInTeam } from '../models/basic';

interface IMultipleUserSearchProps {
  selectedUsers: UserInTeam[];
  setSelectedUsers: (selectedUsers: UserInTeam[]) => void;
}

/**
 * This component is a search bar that allows the user to select multiple users.
 * @param props the props for this component.
 * @returns the MultipleUserSearch component.
 */
const MultipleUserSelect = (props: IMultipleUserSearchProps) => {
  const { selectedUsers, setSelectedUsers } = props;
  const { data: users, error, isLoading } = useGetAllUsersQuery();
  const [searchByEmail, setSearchByEmail] = React.useState(false);

  const handleSelectedValuesChange = (event: React.SyntheticEvent, value: User[]) => {
    // Check if the user is already in the selectedValues array
    // If they are, skip adding them to the array
    const filteredValues = value.filter((user) => !selectedUsers.find((selectedUser) => selectedUser.id === user.id));

    // Add the filtered values to the selectedValues array
    const newSelectedValues = [
      ...selectedUsers,
      ...filteredValues.map((user) => {
        return {
          id: user.id,
          display_name: user.display_name,
          email: user.email,
          country_timezone: user.country_timezone,
          permission_level: user.permission_level,
          experience: 'Select a value',
          role: 'Select a value',
          team_id: ''
        };
      })
    ];

    // Filter out any values that are no longer selected from the newSelectedValues array
    const filteredSelectedValues = newSelectedValues.filter(
      (user) => value.find((selectedUser) => selectedUser.id === user.id) || user.role == 'Manager'
    );
    setSelectedUsers(filteredSelectedValues);
  };

  // Handle the switch to search by email
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchByEmail(event.target.checked);
  };

  return (
    <>
      {error ? (
        <Typography variant="body1" color="red">
          Error loading users
        </Typography>
      ) : isLoading ? (
        <Stack justifyContent="center" alignItems="center">
          <CircularProgress size="4rem" />
        </Stack>
      ) : users ? (
        <>
          <Stack direction="row" sx={{ mb: 3 }} justifyContent="space-between" alignItems="center">
            <Autocomplete
              id="user-autocomplete"
              sx={{ width: '70%' }}
              value={selectedUsers}
              options={users}
              getOptionLabel={(option) => (searchByEmail ? `${option.email}` : `${option.display_name}`)}
              onChange={handleSelectedValuesChange}
              multiple
              filterSelectedOptions
              renderInput={(params) => <TextField {...params} label="Select a user" />}
              isOptionEqualToValue={(option, value) => {
                return option.id === value.id;
              }}
              renderTags={(tagValue, getTagProps) => {
                return tagValue.map((option, index) => (
                  <Chip
                    label={option.display_name}
                    {...getTagProps({ index })}
                    disabled={selectedUsers.some((selUser) => selUser.role == 'Manager' && selUser.id == option.id)}
                  />
                ));
              }}
            />
            <FormControl sx={{ width: '15%' }}>
              <FormControlLabel
                control={<Switch checked={searchByEmail} onChange={handleChange} />}
                sx={{ alignSelf: 'end' }}
                label="Email Search"
              />
            </FormControl>
          </Stack>
        </>
      ) : null}
    </>
  );
};

export default MultipleUserSelect;
