import { configureStore } from '@reduxjs/toolkit';
import { cmtApi } from './slices/apiSlice';
import snackbarReducer from './slices/snackbarSlice';
import viewSelectorReducer from './slices/viewSelectorSlice'
import appStateSlice from './slices/appStateSlice';

const store = configureStore({
    reducer: {
        [cmtApi.reducerPath]: cmtApi.reducer,
        snackbar: snackbarReducer,
        viewSelector: viewSelectorReducer,
        appState: appStateSlice
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat(cmtApi.middleware),
    devTools: true
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {cmtApi: cmtApiState}
export type AppDispatch = typeof store.dispatch

export default store