import { Stack, Typography } from '@mui/material';

const Forbidden = () => {
  return (
    <Stack spacing={2} className=" min-h-screen" alignItems="center" justifyContent="center">
      <Typography variant="h2" component="div" gutterBottom>
        You're not authorized
      </Typography>
      <Typography variant="h6" gutterBottom>
        Sorry! You do not have permission to view this page. Please contact system administrator for help.
      </Typography>
    </Stack>
  );
};

export default Forbidden;
