import React, { useEffect } from 'react';
import { open } from '../redux/slices/snackbarSlice';
import { useAppDispatch } from '../redux/hooks';
import { Button, Stack, Box, CircularProgress } from '@mui/material';
import UserTable from './UserTable';
import { GridRowModesModel } from '@mui/x-data-grid';
import { useEditPositionsMutation, useGetPositionsQuery, useGetTeamRolesQuery } from '../redux/slices/teamApiSlice';
import { Position, TeamEditBody, UserInTeam } from '../models/basic';
import { run_task } from '../utils/task';
import { setSettingsChanged } from '../redux/slices/appStateSlice';

export interface ITeamEditorProps {
  teamId: number;
}

const TeamEditor = (props: ITeamEditorProps) => {
  const dispatch = useAppDispatch();
  const [editTeam] = useEditPositionsMutation();
  const { data } = useGetPositionsQuery(props.teamId);

  // make sure current positions in team line up with current db positions
  const [selectedUsers, setSelectedUsers] = React.useState<UserInTeam[]>([]);
  useEffect(() => {
    if (data) {
      setSelectedUsers(JSON.parse(JSON.stringify(data)));
    }
  }, [data]);

  // check whether any edits has been made
  useEffect(() => {
    setIsUpdated(JSON.stringify(data) !== JSON.stringify(selectedUsers));
  }, [selectedUsers]);

  const [teamMembersAttributesError, setTeamMembersAttributesError] = React.useState(false);
  const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>({});
  const { data: roles } = useGetTeamRolesQuery(props.teamId);
  const [isUpdated, setIsUpdated] = React.useState(false);
  const [isSubmitLoading, setIsSubmitLoading] = React.useState(false);

  const updateUsersInTeam = (users: UserInTeam[]) => {
    setSelectedUsers(users);
    setIsUpdated(JSON.stringify(data) !== JSON.stringify(selectedUsers));
  };

  function handleAbort() {
    setRowModesModel({});
    if (data) {
      setSelectedUsers(JSON.parse(JSON.stringify(data)));
      setTeamMembersAttributesError(false);
    }
  }

  async function handleSubmit() {
    let valid = true;

    selectedUsers.forEach((user) => {
      console.log(user.experience);
      if (user.experience === 'Select a value' || user.role === 'Select a value') {
        setTeamMembersAttributesError(true);
        valid = false;
      }
    });

    if (!valid) return;

    // Add edited team info to the database
    // Add the positions to an array
    const positions = selectedUsers.map((user) => {
      let position: Position = {
        user_id: user.id,
        experience_level: user.experience,
        role: user.role
      };
      return position;
    });

    // Create a TeamEditBody object
    const editReqBody: TeamEditBody = {
      team_id: props.teamId,
      positions: positions
    };

    setIsSubmitLoading(true);
    await editTeam(editReqBody).unwrap()
    .then((val) => {
      if (val && val.task_id) {
        const task_id = val.task_id;
        run_task(task_id, () => {            
          taskCompleted(true);
        }, () => {
          taskCompleted(false);
        });
      } else {
        taskCompleted(false);
      }
    })
    .catch(() => {
      taskCompleted(false);
    });
  }

  function taskCompleted(success: boolean) {
    setIsSubmitLoading(false);    
    setTeamMembersAttributesError(false);
    // Show success snackbar
    dispatch(
      open({
        open: true,
        message: 'Edit successful',
        severity: 'success'
      })
    );
    console.info('Edit successful');
    dispatch(setSettingsChanged(true));
    setTimeout(() => {
      dispatch(setSettingsChanged(false));
    }, 3000);
  }

  return (
    <>
      <UserTable
        selectedUsers={selectedUsers}
        selectedUsersError={false}
        teamMembersAttributesError={teamMembersAttributesError}
        setSelectedUsers={updateUsersInTeam}
        isHorizontal={false}
        rowModesModel={rowModesModel}
        setRowModesModel={setRowModesModel}
        selectableRoles={roles ? roles.filter((roleName) => roleName !== 'Manager') : []}
        lockRole={null}
      />

      <Stack direction={'row'} justifyContent={'flex-end'} spacing={2} className=" mt-4">
        <Button variant="outlined" color="error" disabled={!isUpdated} onClick={handleAbort}>
          Cancel
        </Button>
        <Box sx={{ position: 'relative' }}>
          <Button variant="outlined" color="success" disabled={!isUpdated || isSubmitLoading} onClick={handleSubmit}>
            Submit
          </Button>
          {isSubmitLoading && (
            <CircularProgress
              size={24}
              sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                marginTop: '-12px',
                marginLeft: '-12px'
              }}
            />
          )}
        </Box>
      </Stack>
    </>
  );
};

export default TeamEditor;
