import {
  DataGrid,
  GridActionsCellItem,
  GridColDef,
  GridEventListener,
  GridRowEditStopReasons,
  GridRowId,
  GridRowModel,
  GridRowModes,
  GridRowModesModel,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarFilterButton,
  GridToolbarQuickFilter,
  useGridApiRef
} from '@mui/x-data-grid';
import {
  useAddPublicHolidayMutation,
  useDeletePublicHolidayMutation,
  useGetPublicHolidaysQuery,
  useRefreshPublicHolidaysMutation
} from '../redux/slices/leaveApiSlice';
import { isFetchBaseQueryError, isErrorWithMessage } from '../utils/helpers';
import React from 'react';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  Stack,
  CircularProgress,
  Paper,
  Button,
  Tooltip,
  Autocomplete,
  TextField,
  lighten,
  darken,
  styled
} from '@mui/material';
import { v4 as uuidv4 } from 'uuid';
import { useAppDispatch } from '../redux/hooks';
import { open } from '../redux/slices/snackbarSlice';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { Refresh } from '@mui/icons-material';
import EmptyDatagridOverlay from '../components/EmptyDatagridOverlay';
import { useGetAllTimezonesQuery } from '../redux/slices/userApiSlice';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { CountryTimezone } from '../models/basic';
import { PublicHoliday } from '../models/leave';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.locale('en-gb');

const GroupHeader = styled('div')(({ theme }) => ({
  fontSize: '0.8rem',
  position: 'sticky',
  top: '-8px',
  padding: '4px 10px',
  color: theme.palette.primary.main,
  backgroundColor:
    theme.palette.mode === 'light'
      ? lighten(theme.palette.primary.light, 0.85)
      : darken(theme.palette.primary.main, 0.8)
}));

const GroupItems = styled('ul')({
  padding: 0
});

const PublicHolidays = () => {
  const navigate = useNavigate();
  const {
    data: publicHolidays,
    isLoading: isPublicHolidaysLoading,
    isError: isPublicHolidaysError,
    error: publicHolidaysError
  } = useGetPublicHolidaysQuery();
  const { data: timezoneData, isFetching: isTimezoneFetching } = useGetAllTimezonesQuery();
  const [timezoneOptions, setTimezoneOptions] = React.useState<CountryTimezone[]>([]);
  const [addPublicHoliday, { isLoading: isAdding }] = useAddPublicHolidayMutation();
  const [deletePublicHoliday, { isLoading: isDeleting }] = useDeletePublicHolidayMutation();
  const [refreshPublicHolidays, { isLoading: isRefreshing }] = useRefreshPublicHolidaysMutation();
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [rows, setRows] = React.useState<any[]>(publicHolidays ?? []);
  const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>({});
  const dispatch = useAppDispatch();
  const apiRef = useGridApiRef();

  if (isPublicHolidaysError) {
    if (isFetchBaseQueryError(publicHolidaysError)) {
      if (publicHolidaysError.status === 403) {
        dispatch(open({ open: true, message: 'You are not authorized to view this page', severity: 'error' }));
        navigate('/forbidden');
      } else if (publicHolidaysError.status === 401) {
        dispatch(open({ open: true, message: 'You are not logged in', severity: 'error' }));
        navigate('/login');
      } else {
        dispatch(open({ open: true, message: JSON.stringify(publicHolidaysError.data), severity: 'error' }));
      }
    } else if (isErrorWithMessage(publicHolidaysError)) {
      dispatch(open({ open: true, message: publicHolidaysError.message, severity: 'error' }));
    } else {
      dispatch(open({ open: true, message: 'Error occurred while updating user information', severity: 'error' }));
    }
  }

  React.useEffect(() => {
    if (publicHolidays) {
      setRows(publicHolidays);
    }
    setIsLoading(false);
  }, [publicHolidays]);

  React.useEffect(() => {
    if (timezoneData) {
      setTimezoneOptions(timezoneData);
    }
  }, [timezoneData]);

  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: 'Name',
      flex: 1,
      editable: true
    },
    {
      field: 'country',
      headerName: 'Country',
      flex: 1,
      valueGetter: (params) => {
        return params.row.country_timezone.country_name;
      }
    },
    {
      field: 'country_timezone',
      headerName: 'Timezone',
      flex: 1,
      editable: true,
      renderCell: (params) => {
        if (params.value == null) {
          return <div></div>;
        } else {
          return <div>{params.value.timezone}</div>;
        }
      },
      renderEditCell: (params) => {
        return (
          <Autocomplete
            freeSolo={false}
            fullWidth
            loading={isPublicHolidaysLoading || isTimezoneFetching || isLoading}
            value={params.value}
            isOptionEqualToValue={(option, value) => option.timezone === value.timezone}
            options={timezoneOptions}
            filterOptions={(options, { inputValue }) =>
              options.filter(
                (option) =>
                  option.country_name.toLowerCase().includes(inputValue.toLowerCase()) ||
                  option.timezone.toLowerCase().includes(inputValue.toLowerCase())
              )
            }
            groupBy={(option) => option.country_name}
            getOptionLabel={(option) => option.timezone}
            renderInput={(params) => <TextField {...params} />}
            renderGroup={(params) => (
              <li key={params.key}>
                <GroupHeader>{params.group}</GroupHeader>
                <GroupItems>{params.children}</GroupItems>
              </li>
            )}
            onChange={(event, value) => {
              apiRef.current.setEditCellValue({ id: params.id, field: params.field, value: value });
              params.value = value;
            }}
          />
        );
      }
    },
    {
      field: 'start_date',
      headerName: 'Start Date',
      type: 'date',
      flex: 1,
      editable: true,
      valueGetter: (params) => {
        const startDateString = params.value as string;
        if (startDateString) {
          return moment(startDateString, 'YYYY-MM-DD').toDate();
        }
        return null;
      }
    },
    {
      field: 'end_date',
      headerName: 'End Date',
      type: 'date',
      flex: 1,
      editable: true,
      valueGetter: (params) => {
        const endDateString = params.value as string;
        if (endDateString) {
          return moment(endDateString, 'YYYY-MM-DD').toDate();
        }
        return null;
      }
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      flex: 0.5,
      cellClassName: 'actions',
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              sx={{
                color: 'primary.main'
              }}
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />
          ];
        }

        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
          />,
          <GridActionsCellItem icon={<DeleteIcon />} label="Delete" onClick={handleDeleteClick(id)} color="inherit" />
        ];
      }
    }
  ];

  function EditToolbar() {
    const handleAddClick = async () => {
      const id = uuidv4();
      // Create the new row
      await apiRef.current.updateRows([{ id: id, isNew: true }]);

      // Set the row to edit mode
      apiRef.current.startRowEditMode({ id: id });

      // Set focus to the new row
      apiRef.current.setCellFocus(id, 'name');

      const lastPage = apiRef.current.getRowsCount() / apiRef.current.state.pagination.paginationModel.pageSize;
      apiRef.current.setPage(Math.ceil(lastPage)); // Navigate to the last page
    };

    const handleRefreshClick = async () => {
      await refreshPublicHolidays().unwrap();
      dispatch(open({ open: true, message: 'Public holidays refreshed', severity: 'success' }));
    };

    return (
      <GridToolbarContainer
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}
      >
        <div>
          <GridToolbarColumnsButton />
          <GridToolbarFilterButton />
          <GridToolbarExport />
          <Button color="primary" startIcon={<AddIcon />} onClick={handleAddClick}>
            Add record
          </Button>
          <Tooltip title="Refreshing may overwrite current year's changes" arrow>
            <Button color="primary" startIcon={<Refresh />} onClick={handleRefreshClick}>
              Refresh holidays
            </Button>
          </Tooltip>
        </div>
        <GridToolbarQuickFilter />
      </GridToolbarContainer>
    );
  }

  const handleRowEditStop: GridEventListener<'rowEditStop'> = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleEditClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleCancelClick = (id: GridRowId) => () => {
    apiRef.current.stopRowEditMode({ id: id, ignoreModifications: true });
    const row = apiRef.current.getRow(id);
    if (row.isNew) {
      apiRef.current.updateRows([{ id: id, _action: 'delete' }]);
    }
  };

  const processRowUpdate = React.useCallback(
    async (newRow: GridRowModel) => {
      console.log(newRow);
      const payload = {
        id: newRow.id,
        name: newRow.name,
        country_timezone: newRow.country_timezone,
        start_date: moment(newRow.start_date).format('YYYY-MM-DD'),
        end_date: moment(newRow.end_date).format('YYYY-MM-DD')
      } as PublicHoliday;
      console.log(payload);
      const response = await addPublicHoliday(payload).unwrap();
      // Return a success flag or a specific value
      dispatch(open({ open: true, message: 'Public holiday added successfully', severity: 'success' }));
      console.log(response);
      return response;
    },
    [addPublicHoliday, dispatch]
  );

  const handleProcessRowUpdateError = React.useCallback(
    (error: Error) => {
      if (isFetchBaseQueryError(error)) {
        if (error.status === 403) {
          dispatch(open({ open: true, message: 'You are not authorized to make changes', severity: 'error' }));
        } else {
          dispatch(open({ open: true, message: JSON.stringify(error.data), severity: 'error' }));
        }
      } else if (isErrorWithMessage(error)) {
        dispatch(open({ open: true, message: error.message, severity: 'error' }));
      } else {
        dispatch(open({ open: true, message: 'Error occurred while adding public holiday', severity: 'error' }));
      }
    },
    [dispatch]
  );

  const handleDeleteClick = (id: GridRowId) => () => {
    deletePublicHoliday(Number.parseInt(id.toString()))
      .unwrap()
      .then(() => {
        dispatch(open({ open: true, message: 'Public holiday deleted successfully', severity: 'info' }));
        apiRef.current.updateRows([{ id: id, _action: 'delete' }]);
      });
  };

  const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  return (
    <Stack sx={{ width: '100%', p: 4 }} alignItems="center" justifyContent="center">
      {isLoading ? (
        <div className=" h-screen w-full flex items-center justify-center">
          <CircularProgress size="16rem" />
        </div>
      ) : (
        <Paper sx={{ width: '90%', p: 4, height: '90vh' }} elevation={3}>
          <DataGrid
            initialState={{
              pagination: { paginationModel: { pageSize: 25 } }
            }}
            pageSizeOptions={[25, 50, 100]}
            apiRef={apiRef}
            disableEval={true}
            rows={rows}
            columns={columns}
            loading={isPublicHolidaysLoading || isAdding || isDeleting || isRefreshing}
            editMode="row"
            rowModesModel={rowModesModel}
            onRowModesModelChange={handleRowModesModelChange}
            onRowEditStop={handleRowEditStop}
            processRowUpdate={processRowUpdate}
            onProcessRowUpdateError={handleProcessRowUpdateError}
            slots={{
              toolbar: EditToolbar,
              noRowsOverlay: () => <EmptyDatagridOverlay message={'No Holidays found!'} />
            }}
            slotProps={{
              toolbar: { showQuickFilter: true, setRows, setRowModesModel }
            }}
          />
        </Paper>
      )}
    </Stack>
  );
};

export default PublicHolidays;
