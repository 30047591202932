import { Routes, Route, Navigate, Outlet } from 'react-router-dom';
import SideBar from './components/SideBar';
import SnackBar from './components/SnackBar';
import Login from './pages/Login';
import Logout from './pages/Logout';
import { AuthOutlet } from './utils/AuthOutlet';
import Teams from './pages/Teams';
import Home from './pages/Home';
import Users from './pages/Users';
import PublicHolidays from './pages/PublicHolidays';
import Settings from './pages/Settings';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import Forbidden from './pages/Forbidden';
import { PermissionLevel } from './models/permissionLevels';
import Health from './pages/Health';

function App() {
  const locale = 'en-gb';

  const AuthenticatedLayout = () => (
    <>
      <SideBar />
      <div className=" ml-16 w-full overflow-auto">
        <SnackBar />
        <Outlet />
      </div>
    </>
  );

  const UnAuthenticatedLayout = () => (
    <>
      <div className=" ml-16 w-full overflow-auto">
        <Outlet />
      </div>
    </>
  );

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={locale}>
      <div className=" flex w-full">
        <Routes>
          <Route element={<UnAuthenticatedLayout />}>
            <Route path="/login" element={<Login />} />
            <Route path="/health" element={<Health />} />
          </Route>
          <Route element={<AuthenticatedLayout />}>
            {/* Every user can access these */}
            <Route element={<AuthOutlet permissionLevels={[PermissionLevel.USER]} />}>
              <Route path="/" element={<Navigate to="/home" replace={true} />}></Route>
              <Route path="/home" element={<Home />}></Route>
              <Route path="/profile" element={<div>Profile</div>} />
              <Route path="/logout" element={<Logout />} />
              <Route path="/forbidden" element={<Forbidden />} />
            </Route>
            {/* Only managers or site admin can access these */}
            <Route element={<AuthOutlet permissionLevels={[PermissionLevel.MANAGER]} />}>
              <Route path="/teams" element={<Teams />} />
              <Route path="/users" element={<Users />} />
            </Route>
            {/* Only site admin can access these */}
            <Route element={<AuthOutlet permissionLevels={[PermissionLevel.ADMIN]} />}>
              <Route path="/holidays" element={<PublicHolidays />} />
              <Route path="/settings" element={<Settings />} />
            </Route>
          </Route>
        </Routes>
      </div>
    </LocalizationProvider>
  );
}

export default App;
