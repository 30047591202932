
export const settings = [
    {
        'HOST': 'localhost',
        'CMT_ENV': 'dev',
        'APP_API_URL': 'https://crack-mint-skylark.ngrok-free.app/api',
        'JWT_ENABLE_AUTO_REFRESH_TOKEN': true
    },
    {
        'HOST': 'tst-cmt.telematics.com',
        'CMT_ENV': 'test',
        'APP_API_URL': 'https://tst-cmt.telematics.com/api',
        'JWT_ENABLE_AUTO_REFRESH_TOKEN': true
    }
];

export function get_settings() {
    const host = window.location.hostname;
    const hs = settings.filter(s => s.HOST === host);
    return hs && hs.length > 0 ? hs[0] : settings[0];
}