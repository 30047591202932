import { Navigate } from 'react-router-dom';
import { clearJwtToken } from '../utils/auth';

const Logout = () => {
  clearJwtToken();
  return (
    <Navigate to="/login" replace={true} />
  );
};

export default Logout;
