import {
  Box,
  Button,
  Step,
  StepButton,
  Stepper,
  Typography,
  TextField,
  Paper,
  Stack,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Tooltip,
  FormHelperText,
  Autocomplete,
  Checkbox,
  CircularProgress
} from '@mui/material';
import React from 'react';
import SnackBar from '../components/SnackBar';
import UserTable from '../components/UserTable';
import TeamOverview from '../components/TeamOverview';
import { useAppDispatch } from '../redux/hooks';
import { useNavigate } from 'react-router-dom';
import { open } from '../redux/slices/snackbarSlice';
import { isFetchBaseQueryError, isErrorWithMessage } from '../utils/helpers';
import SingleUserSelect from '../controls/SingleUserSelect';
import { useAddTeamMutation, useGetSprintGroupsQuery } from '../redux/slices/teamApiSlice';
import { useGetAllUsersWithPermissionsQuery, useGetProfileQuery } from '../redux/slices/userApiSlice';
import { useGetGlobalPermissionQuery } from '../redux/slices/authApiSlice';
import { GridRowModesModel } from '@mui/x-data-grid';
import { useGetRolesQuery } from '../redux/slices/positionApiSlice';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { isNonNullExpression } from 'typescript';
import { Position, TeamInit, UserInTeam } from '../models/basic';
import { run_task } from '../utils/task';

const STEPS = ['Create a team', 'Add members', 'Confirm team'];
const MIN_TEAM_NAME_LENGTH = 1;
const MAX_TEAM_NAME_LENGTH = 50;
const MANAGER_PERMISSION = 3;
const VERTICAL_TEAM_LABEL = 'All / Vertical Team';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

/**
 * This component is used to create a team.
 * @returns the Teams component.
 */
const Teams = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [addTeam] = useAddTeamMutation();

  const [activeStep, setActiveStep] = React.useState(0);
  const [teamName, setTeamName] = React.useState('');
  const [teamNameError, setTeamNameError] = React.useState(false);
  const [teamId, setTeamId] = React.useState('');
  const [selectedUsers, setSelectedUsers] = React.useState<UserInTeam[]>([]);
  const [selectedUsersError, setSelectedUsersError] = React.useState(false);
  const [teamMembersAttributesError, setTeamMembersAttributesError] = React.useState(false);
  const [completed, setCompleted] = React.useState<{ [k: number]: boolean }>({});
  const [manager, setManager] = React.useState<UserInTeam | null>(null);
  const [managerError, setManagerError] = React.useState(false);
  const [sprintGroupId, setSprintGroupId] = React.useState<number>();
  const [sprintGroupError, setSprintGroupError] = React.useState(false);
  const { data: loggedInUser } = useGetProfileQuery();
  const { data: userPermission } = useGetGlobalPermissionQuery();
  const { data: roles } = useGetRolesQuery();
  const { data: sprintGroups } = useGetSprintGroupsQuery();
  const { data, error, isLoading } = useGetAllUsersWithPermissionsQuery({permissions:['MANAGER', 'ADMIN'],team_id:null});
  const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>({});
  const [selectedRoles, setSelectedRoles] = React.useState<string[]>([]);
  const [selectableRoles, setSelectableRoles] = React.useState<string[]>([]);
  const [isSubmitLoading, setIsSubmitLoading] = React.useState(false);

  // Set the manager to the logged in user if the userPermission is manager
  React.useEffect(() => {
    if (loggedInUser && userPermission !== undefined && userPermission === MANAGER_PERMISSION) {
      // Convert loggedInUser to userInTeam and set it as the manager
      setManager({
        id: loggedInUser.id,
        display_name: loggedInUser.display_name,
        email: loggedInUser.email,
        country_timezone: loggedInUser.country_timezone,
        team_id: teamId,
        role: 'Manager',
        experience: 'Select a value'
      } as UserInTeam);
    }
  }, [userPermission, loggedInUser]);

  React.useEffect(() => {
    if (roles) {
      const rolesArr = roles.map((role, index) => role.role_name).filter((roleName) => roleName !== 'Manager');
      // Add all option to the start of the roles array
      rolesArr.unshift(VERTICAL_TEAM_LABEL);

      setSelectableRoles(rolesArr);
    }
  }, [roles]);

  // Basic validation function for each step
  function validateStep(step: number) {
    let valid = true;
    setTeamNameError(false);
    setSelectedUsersError(false);
    setTeamMembersAttributesError(false);
    setManagerError(false);
    setSprintGroupError(false);

    // Validate team name if step 0
    if (step === 0) {
      if (teamName.length < MIN_TEAM_NAME_LENGTH || teamName.length > MAX_TEAM_NAME_LENGTH) {
        setTeamNameError(true);
        valid = false;
      }

      if (!sprintGroupId) {
        setSprintGroupError(true);
        valid = false;
      }

      if (manager === null) {
        setManagerError(true);
        valid = false;
      } else {
        // Set the manager's role to manager
        manager.role = 'Manager';
        // Find all users that have the role manager and remove them
        const filteredUsers = selectedUsers.filter((user) => user.role !== 'Manager' && user.id !== manager.id);
        // Add the manager back to the array
        filteredUsers.push(manager);
        // Update the selected users array
        setSelectedUsers(filteredUsers);
        // Reset the grid row modes
        setRowModesModel({});
      }

      // Validate team members if step 1
    } else if (step === 1) {
      if (selectedUsers.length === 0) {
        setSelectedUsersError(true);
        valid = false;
      }

      // Loop through the selected users, and check that experience and role values are set
      for (let i = 0; i < selectedUsers.length; i++) {
        if (selectedUsers[i].experience === 'Select a value' || selectedUsers[i].role === 'Select a value') {
          setTeamMembersAttributesError(true);
          valid = false;
          break;
        }
      }
    }
    return valid;
  }

  const handleSubmit = async () => {
    // Add the team to the database
    // Add the positions to an array
    const positions = selectedUsers.map((user) => {
      let position: Position = {
        user_id: user.id,
        experience_level: user.experience,
        role: user.role
      };
      return position;
    });

    //validate team information
    if (!teamName || !positions || !sprintGroupId) {
      dispatch(
        open({
          open: true,
          message: 'Team info missing.',
          severity: 'error'
        })
      );
      return;
    }

    // Create a team init object
    const teamInit: TeamInit = {
      team_name: teamName,
      is_horizontal: !selectedRoles.includes(VERTICAL_TEAM_LABEL),
      roles: selectedRoles,
      positions: positions,
      sprint_group_id: sprintGroupId
    };
    setIsSubmitLoading(true);
    await addTeam(teamInit).unwrap()    
    .then((val) => {
      if (val && val.task_id) {
        const task_id = val.task_id;
        run_task(task_id, () => {            
          taskCompleted(true);
        }, () => {
          taskCompleted(false);
        });
      } else {
        taskCompleted(false);
      }
    })
    .catch(() => {
      taskCompleted(false);
    });
  };

  function taskCompleted(success: boolean) {
    setIsSubmitLoading(false);
    // Show success snackbar and navigate to the team page
    dispatch(
      open({
        open: true,
        message: 'Team created successfully',
        severity: 'success'
      })
    );
    navigate(`/home`);
    console.info('Team created successfully');
  }

  const handleNext = () => {
    if (validateStep(activeStep)) {
      if (activeStep === STEPS.length - 1) {
        handleSubmit();
      } else {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      }
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const updateUsersInTeam = (users: UserInTeam[]) => {
    setSelectedUsers(users);
  };

  const handleStep = (step: number) => () => {
    setActiveStep(step);
  };

  function getTeamTypeDisplayName(teamTypeValue: null | string) {
    if (!teamTypeValue) {
      return 'Vertical Team';
    } else {
      return teamTypeValue;
    }
  }

  function getSprintGroupDisplayName(sprintGroupId: undefined | number) {
    const sprintGroup = sprintGroups?.find((sprintGroup) => sprintGroup.id === sprintGroupId);
    if (sprintGroup) {
      return sprintGroup.sprint_group_name;
    } else {
      return '';
    }
  }

  const step1content = (
    <Paper elevation={1} sx={{ width: { xs: '90%', md: '70%', lg: '60%', xl: '40%' } }}>
      <SnackBar />
      <Stack spacing={2} sx={{ p: 3 }} justifyContent="center" alignItems="center">
        <Typography variant="h4" align="center">
          Create a Team
        </Typography>
        <img src="navman-logo.png" alt="Teletrac Navman Logo" width="350rem" />

        <TextField
          id="teamname-input"
          label="Team Name"
          variant="outlined"
          error={teamNameError}
          helperText={teamNameError ? 'Team name must be between 1 and 50 characters' : ''}
          sx={{ width: '100%' }}
          value={teamName}
          onChange={(e) => setTeamName(e.target.value)}
        />
        <Autocomplete
          multiple
          limitTags={2}
          value={selectedRoles || null}
          options={selectableRoles}
          disableCloseOnSelect
          getOptionLabel={(option) => option}
          getOptionDisabled={(option) => {
            if (selectedRoles.length > 0) {
              if (option.includes('Developer') && selectedRoles[0].includes('Developer')) {
                return false;
              } else {
                return !selectedRoles.includes(option);
              }
            } else {
              return false;
            }
          }}
          renderOption={(props, option, { selected }) => (
            <li {...props}>
              <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
              {option}
            </li>
          )}
          style={{ width: '100%' }}
          renderInput={(params) => <TextField {...params} label="Available Roles" />}
          onChange={(event, value) => {
            setSelectedRoles(value);
          }}
        />
        <FormControl fullWidth>
          <InputLabel error={sprintGroupError} id="team-sprint-group-label" className=" bg-white">
            Select Sprint Group
          </InputLabel>
          <Select
            error={sprintGroupError}
            id="team-sprint-group-sel"
            labelId="team-sprint-group-label"
            value={getSprintGroupDisplayName(sprintGroupId)}
          >
            {sprintGroups?.map((sprintGroup, index) => (
              <MenuItem
                key={index}
                onClick={() => {
                  setSprintGroupId(sprintGroup.id);
                }}
                value={sprintGroup.sprint_group_name}
              >
                {sprintGroup.sprint_group_name}
              </MenuItem>
            ))}
          </Select>
          <FormHelperText error={sprintGroupError} hidden={!sprintGroupError}>
            Sprint group required
          </FormHelperText>
        </FormControl>

        <SingleUserSelect
          selectedUser={manager}
          setSelectedUser={setManager}
          selectedUserError={managerError}
          disabled={userPermission === MANAGER_PERMISSION}
          helperText={managerError ? 'Please select a team manager' : ''}
          allUsers={data}
          isErrorGettingUsers={error}
          isLoadingUsers={isLoading}
          label="Select a Manager"
        />
      </Stack>
    </Paper>
  );

  const step2content = (
    <Paper elevation={1} sx={{ p: 2, width: '100%' }}>
      <Stack spacing={2}>
        <Typography variant="h4" align="center">
          Add Members to Team {teamName}
        </Typography>
        <UserTable
          setSelectedUsers={updateUsersInTeam}
          selectedUsers={selectedUsers}
          selectedUsersError={selectedUsersError}
          teamMembersAttributesError={teamMembersAttributesError}
          isHorizontal={!selectedRoles.includes(VERTICAL_TEAM_LABEL)}
          rowModesModel={rowModesModel}
          setRowModesModel={setRowModesModel}
          selectableRoles={selectedRoles.includes(VERTICAL_TEAM_LABEL) ? selectableRoles : selectedRoles}
          lockRole={
            selectedRoles.length === 1 && !selectedRoles.includes(VERTICAL_TEAM_LABEL) ? selectedRoles[0] : null
          }
        />
      </Stack>
    </Paper>
  );

  const step3content = (
    <Paper elevation={1} sx={{ p: 2 }}>
      <Stack spacing={2}>
        <Typography variant="h4" align="center">
          Confirm Team
        </Typography>
        <TeamOverview
          teamMembers={selectedUsers}
          teamName={teamName}
          isHorizontalTeam={!selectedRoles.includes(VERTICAL_TEAM_LABEL)}
        />
      </Stack>
    </Paper>
  );

  return (
    <Stack sx={{ width: '100%', p: 4 }} alignItems="center" justifyContent="center">
      <Paper sx={{ width: '90%', p: 2 }} elevation={3}>
        <Stepper activeStep={activeStep}>
          {STEPS.map((label, index) => (
            <Step key={label} completed={completed[index]}>
              <StepButton color="inherit" onClick={() => handleStep(index)}>
                {label}
              </StepButton>
            </Step>
          ))}
        </Stepper>
        {activeStep === STEPS.length ? (
          <React.Fragment>
            <Typography sx={{ mt: 2, mb: 1 }}>All steps completed - you&apos;re finished</Typography>
            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
              <Box sx={{ flex: '1 1 auto' }} />
              <Button onClick={handleReset}>Reset</Button>
            </Box>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <Stack sx={{ mt: 2, p: 4 }} alignItems="center" justifyContent="center">
              {activeStep === 0 ? step1content : activeStep === 1 ? step2content : step3content}
            </Stack>
            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
              <Button color="inherit" disabled={activeStep === 0} onClick={handleBack} sx={{ mr: 1 }}>
                Back
              </Button>
              <Box sx={{ flex: '1 1 auto' }} />

              <Box sx={{ position: 'relative' }}>
                <Button onClick={handleNext} disabled={isSubmitLoading}>
                  {activeStep === STEPS.length - 1 ? 'Finish' : 'Next'}
                </Button>
                {isSubmitLoading && (
                  <CircularProgress
                    size={24}
                    sx={{
                      position: 'absolute',
                      top: '50%',
                      left: '50%',
                      marginTop: '-12px',
                      marginLeft: '-12px'
                    }}
                  />
                )}
              </Box>
            </Box>
          </React.Fragment>
        )}
      </Paper>
    </Stack>
  );
};

export default Teams;
