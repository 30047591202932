import * as React from 'react';
import {
  Button,
  FormControl,
  Grid,
  Stack,
  TextField,
  Typography
} from '@mui/material';
import { useAppDispatch } from '../redux/hooks';
import { open } from '../redux/slices/snackbarSlice';
import { useSetTeamSprintCommittedSpMutation } from '../redux/slices/teamApiSlice';
import { SprintCommittedSP } from '../models/basic';


const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: '3rem',
  borderRadius: '0.5rem',
  width: '50%'
};

interface SprintCommittedSPFormProps {
  sprint: SprintCommittedSP | undefined;
  onSave: (sc: SprintCommittedSP) => void;
  onClose: () => void;
}

const SprintCommittedSPForm = (props: SprintCommittedSPFormProps) => {
  const dispatch = useAppDispatch();
  const [updateTeamsprintCommittedSp] = useSetTeamSprintCommittedSpMutation();
  const [data, setData] = React.useState(props.sprint);

  async function handleSubmit() {
    if (data && data.sp_committed && data.sp_committed.length > 0) {
      if (!isNaN(Number(data.sp_committed))) {
        await updateTeamsprintCommittedSp({
          team_id: data.team_id,
          sprint_id: data.sprint_id,
          sp_committed: Number(data.sp_committed)
        }).unwrap();
        props.onSave(data);
        // Show success snackbar and navigate to the team page
        dispatch(
          open({
            open: true,
            message: 'Sprint committed story points saved successfully',
            severity: 'success'
          })
        );
      }
    }
  }

  function setSprintCommittedSP(val: string) {
    if (data) {
      setData({ ...data, sp_committed: val.length > 0 ? Number(val).toString() : '0' });
    }
    return true;
  }

  return (
    <Stack spacing={2}>
      {data && (
        <>
          <Grid container spacing={2} sx={{ width: '100%' }}>
              <Grid item xs={12} key="sprint_committed_sp">
                <FormControl fullWidth>
                  <label>Committed Story Points</label>
                  <TextField
                    variant="outlined"
                    type={'number'}
                    value={(data.sp_committed || 0).toString()}
                    onChange={(e) => setSprintCommittedSP(e.target.value)}
                  />
                </FormControl>
              </Grid>
          </Grid>
          <Stack direction={'row'}>
            <div style={{ flexGrow: '1' }}></div>
            <Button variant="contained" sx={{ color: 'white', margin: '0 8px' }} onClick={handleSubmit}>
              Save
            </Button>
            <Button variant="contained" color="secondary" sx={{ color: 'white', margin: '0 8px' }} onClick={props.onClose}>
              Close
            </Button>
          </Stack>
        </>
      )}
    </Stack>
  );
};


export default SprintCommittedSPForm;