import * as React from 'react';
import SettingsForm from '../components/SettingsForm';
import { Stack, Typography } from '@mui/material';

const Settings = () => {
    return (
    <Stack spacing={4} justifyContent="center" alignItems="center" sx={{"margin": "1.5rem 8px"}}>
        <Typography variant="h4">Global Settings</Typography>
        <SettingsForm></SettingsForm>
      </Stack>
    );
};


export default Settings;