import { Experience, Role } from '../../models/basic';
import { cmtApi } from './apiSlice';

export const positionApiSlice = cmtApi.injectEndpoints({
    endpoints: builder => ({
        getRoles: builder.query<Role[], void>({
            query: (body) => ({
                url: `roles`,
                method: 'GET',
                body,
            }),
        }),
        getExperiences: builder.query<Experience[],void>({
            query: (body) => ({
                url:'experiences',
                method: 'GET',
                body
            })
        })
    })
})

export const { useGetRolesQuery, useGetExperiencesQuery } = positionApiSlice;
