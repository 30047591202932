import * as React from 'react';
import {
  DataGrid,
  GridActionsCellItem,
  GridColDef,
  GridEventListener,
  GridRowEditStopReasons,
  GridRowId,
  GridRowModel,
  GridRowModes,
  GridRowModesModel,
  GridToolbar,
  useGridApiRef
} from '@mui/x-data-grid';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import {
  useGetAllTimezonesQuery,
  useGetUsersQuery,
  useSetUserCountryTimezoneMutation
} from '../redux/slices/userApiSlice';
import { useSetGlobalPermissionMutation, UserGlobalPermissionUpdate } from '../redux/slices/authApiSlice';
import { Stack, Paper, CircularProgress, TextField, darken, lighten, styled } from '@mui/material';
import { useAppDispatch } from '../redux/hooks';
import { open } from '../redux/slices/snackbarSlice';
import { isFetchBaseQueryError, isErrorWithMessage } from '../utils/helpers';
import Autocomplete from '@mui/material/Autocomplete';
import { useNavigate } from 'react-router-dom';
import { permissionLevelOptions } from '../models/permissionLevels';
import { CountryTimezone } from '../models/basic';
import { UserGlobalPermissionView } from '../models/view';

const GroupHeader = styled('div')(({ theme }) => ({
  fontSize: '0.8rem',
  position: 'sticky',
  top: '-8px',
  padding: '4px 10px',
  color: theme.palette.primary.main,
  backgroundColor:
    theme.palette.mode === 'light'
      ? lighten(theme.palette.primary.light, 0.85)
      : darken(theme.palette.primary.main, 0.8)
}));

const GroupItems = styled('ul')({
  padding: 0
});

const Users = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { data: users, isFetching: isUsersFetching, isError: isUsersError, error: usersError } = useGetUsersQuery();
  const { data: timezoneData, isFetching: isTimezoneFetching } = useGetAllTimezonesQuery();
  const [timezoneOptions, setTimezoneOptions] = React.useState<CountryTimezone[]>([]);
  const [setUserCountryTimezone] = useSetUserCountryTimezoneMutation();
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [rows, setRows] = React.useState<UserGlobalPermissionView[]>(users ?? []);
  const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>({});
  const [setPermission] = useSetGlobalPermissionMutation();
  const apiRef = useGridApiRef();

  if (isUsersError) {
    if (isFetchBaseQueryError(usersError)) {
      if (usersError.status === 403) {
        dispatch(open({ open: true, message: 'You are not authorized to view this page', severity: 'error' }));
        navigate('/forbbiden');
      } else if (usersError.status === 401) {
        dispatch(open({ open: true, message: 'You are not logged in', severity: 'error' }));
        navigate('/login');
      } else {
        dispatch(open({ open: true, message: JSON.stringify(usersError.data), severity: 'error' }));
      }
    } else if (isErrorWithMessage(usersError)) {
      dispatch(open({ open: true, message: usersError.message, severity: 'error' }));
    } else {
      dispatch(open({ open: true, message: 'Error occurred while updating user information', severity: 'error' }));
    }
  }

  React.useEffect(() => {
    setIsLoading(true);
    if (users) {
      setRows(users);
    }
    setIsLoading(false);
  }, [users]);

  React.useEffect(() => {
    if (timezoneData) {
      setTimezoneOptions(timezoneData);
    }
  }, [timezoneData]);

  const handleRowEditStop: GridEventListener<'rowEditStop'> = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleEditClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleCancelClick = (id: GridRowId) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true }
    });
  };

  const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const processRowUpdate = React.useCallback(
    async (newRow: GridRowModel, oldRow: GridRowModel) => {
      const permissionUpdate = {
        user_id: newRow.id,
        permission_level: newRow.permission_level
      } as UserGlobalPermissionUpdate;

      // Set the new permission
      await setPermission(permissionUpdate).unwrap();

      // Set the users timezone if it has changed
      if (oldRow.timezone !== newRow.timezone) {
        console.log("Updating user's timezone");
        await setUserCountryTimezone({
          user_id: newRow.id,
          country_timezone: {
            country_name: newRow.timezone.country_name,
            country_code: newRow.timezone.country_code,
            timezone: newRow.timezone.timezone
          }
        }).unwrap();
      }

      dispatch(
        open({
          open: true,
          message: 'User information updated successfully',
          severity: 'success'
        })
      );

      return newRow;
    },
    [setPermission, setUserCountryTimezone, dispatch]
  );

  const handleProcessRowUpdateError = React.useCallback(
    (error: Error) => {
      if (isFetchBaseQueryError(error)) {
        if (error.status === 403) {
          dispatch(open({ open: true, message: 'You are not authorized to make changes', severity: 'error' }));
        } else {
          dispatch(open({ open: true, message: JSON.stringify(error.data), severity: 'error' }));
        }
      } else if (isErrorWithMessage(error)) {
        dispatch(open({ open: true, message: error.message, severity: 'error' }));
      } else {
        dispatch(open({ open: true, message: 'Error occurred while updating user information', severity: 'error' }));
      }
    },
    [dispatch]
  );

  const columns: GridColDef[] = [
    {
      field: 'display_name',
      headerName: 'Display Name',
      flex: 1
    },
    {
      field: 'timezone',
      headerName: 'Timezone',
      flex: 1,
      editable: true,
      renderCell: (params) => {
        if (params.value == null) {
          return <div></div>;
        } else {
          return <div>{params.value.timezone}</div>;
        }
      },
      renderEditCell: (params) => {
        return (
          <Autocomplete
            freeSolo={false}
            fullWidth
            loading={isUsersFetching || isTimezoneFetching || isLoading}
            value={params.value || null}
            isOptionEqualToValue={(option, value) => {
              return option.timezone === value.timezone || (value.timezone === null && option.timezone === '');
            }}
            options={timezoneOptions.concat({
              country_code: null,
              country_name: 'Select a Timezone',
              timezone: ''
            })}
            getOptionDisabled={(option) => {
              return option.timezone === '';
            }}
            filterOptions={(options, { inputValue }) =>
              options.filter(
                (option) =>
                  option.country_name.toLowerCase().includes(inputValue.toLowerCase()) ||
                  option.timezone.toLowerCase().includes(inputValue.toLowerCase())
              )
            }
            groupBy={(option) => option.country_name}
            getOptionLabel={(option) => (option.timezone ? option.timezone : '')}
            renderInput={(params) => <TextField {...params} />}
            renderGroup={(params) => (
              <li key={params.key}>
                <GroupHeader>{params.group}</GroupHeader>
                <GroupItems>{params.children}</GroupItems>
              </li>
            )}
            onChange={(event, value) => {
              apiRef.current.setEditCellValue({ id: params.id, field: params.field, value: value });
              params.value = value;
            }}
          />
        );
      }
    },
    {
      field: 'email',
      headerName: 'Email',
      flex: 1
    },
    {
      field: 'permission_level',
      headerName: 'Permission Level',
      flex: 0.8,
      editable: true,
      type: 'singleSelect',
      valueOptions: permissionLevelOptions
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      flex: 0.5,
      cellClassName: 'actions',
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              sx={{
                color: 'primary.main'
              }}
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />
          ];
        }

        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
          />
        ];
      }
    }
  ];

  return (
    <Stack sx={{ width: '100%', p: 4 }} alignItems="center" justifyContent="center">
      {isLoading ? (
        <div className=" h-screen w-full flex items-center justify-center">
          <CircularProgress size="16rem" />
        </div>
      ) : (
        <Paper sx={{ width: '90%', p: 4 }} elevation={3}>
          <DataGrid
            apiRef={apiRef}
            rows={rows}
            columns={columns}
            editMode="row"
            rowModesModel={rowModesModel}
            onRowModesModelChange={handleRowModesModelChange}
            onRowEditStop={handleRowEditStop}
            processRowUpdate={processRowUpdate}
            onProcessRowUpdateError={handleProcessRowUpdateError}
            slots={{ toolbar: GridToolbar }}
            slotProps={{
              toolbar: { showQuickFilter: true }
            }}
            onCellKeyDown={(params, event) => {
              if (event.key === 'Enter') {
                event.defaultMuiPrevented = true;
              }
            }}
          />
        </Paper>
      )}
    </Stack>
  );
};

export default Users;
