import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Sprint, SprintCommittedSP, Team, User } from "../../models/basic";

export interface AppState {
    currentUser: User | null,
    currentTeam: Team | null,
    currentSprint: Sprint | null,
    sprints: Sprint[],
    settingsChanged: boolean,
    dataRefreshed: boolean,
    sprintInfoChanged: SprintCommittedSP | null
}

const initialState: AppState = {
    currentUser: null,
    currentTeam: null,
    currentSprint: null,
    sprints: [],
    settingsChanged: false,
    dataRefreshed: false,
    sprintInfoChanged: null
}

const appStateSlice = createSlice({
    name:'appState',
    initialState,
    reducers: {
        setCurrentUser: (state, action:PayloadAction<User|null>) => {
            //console.log('setCurrentUser:' + action.payload?.display_name);
            state.currentUser = action.payload
        },
        setCurrentTeam: (state, action:PayloadAction<Team|null>) => {
            //console.log('setCurrentTeam:' + action.payload?.team_name);
            state.currentTeam = action.payload
        },
        setCurrentSprint: (state, action:PayloadAction<Sprint|null>) => {
            //console.log('setCurrentSprint:' + action.payload?.sprint_name);
            if (action.payload) {
                state.currentSprint = {
                    sprint_id: action.payload.sprint_id,
                    sprint_name: action.payload.sprint_name,
                    start_date: action.payload.start_date,
                    end_date: action.payload.end_date,
                    finished_date: action.payload.finished_date,
                } 
            }
        },
        setSprints: (state, action:PayloadAction<Sprint[]>) => {
            //console.log('setSprints:' + action.payload?.length);
            state.sprints = action.payload
        },
        setSettingsChanged: (state, action:PayloadAction<boolean>) => {
            //console.log('setSettingsChanged:' + action.payload);
            state.settingsChanged = action.payload
        },
        setDataRefreshed: (state, action:PayloadAction<boolean>) => {
            //console.log('setSettingsChanged:' + action.payload);
            state.dataRefreshed = action.payload
        },
        setSprintInfoChanged: (state, action:PayloadAction<SprintCommittedSP | null>) => {
            //console.log('setSettingsChanged:' + action.payload);
            if (action.payload !== null) {
                if (state.sprintInfoChanged === null || 
                    state.sprintInfoChanged.sprint_id !== action.payload.sprint_id ||
                    state.sprintInfoChanged.sp_committed !== action.payload.sp_committed
                    ) {
                    state.sprintInfoChanged = action.payload
                }
            }
        },
    }
})

export default appStateSlice.reducer
export const {setCurrentUser, setCurrentTeam, setCurrentSprint, setSprints, setSettingsChanged, setDataRefreshed, setSprintInfoChanged} = appStateSlice.actions