import React from 'react';
import {
  MenuItem,
  Select,
  Stack,
  Switch,
  FormControlLabel,
  FormGroup,
  ListSubheader,
  Button,
  CircularProgress,
  Typography,
  Box,
  IconButton,
  Tooltip
} from '@mui/material';
import { useGetUserViewsQuery } from '../redux/slices/userApiSlice';
import { setDataRefreshed } from '../redux/slices/appStateSlice';
import { useAppDispatch, useAppSelector } from '../redux/hooks';
import { updateCurrentViewInfo, updateIsManagerView } from '../redux/slices/viewSelectorSlice';
import { useGetTeamInfoQuery, useUpdateTeamDataMutation } from '../redux/slices/teamApiSlice';
import { setCurrentTeam } from '../redux/slices/appStateSlice';
import { run_task } from '../utils/task';
import { open } from '../redux/slices/snackbarSlice';
import moment from 'moment';
import { TeamViewInfo, ViewInfo } from '../models/view';
import { Team } from '../models/basic';
import { FaGear } from 'react-icons/fa6';

interface ViewSelectorProps {
  onTeamSettingsClick: (teamId: number) => void;
}

function ViewSelector(props: ViewSelectorProps) {
  const { data: userViews, isLoading } = useGetUserViewsQuery();
  const isManagerView = useAppSelector((state) => state.viewSelector.isManagerView);
  const selectedViewInfo = useAppSelector((state) => state.viewSelector.currentViewInfo);
  const { data: teamInfo } = useGetTeamInfoQuery(
    selectedViewInfo?.view_type === 'TeamView' ? (selectedViewInfo as TeamViewInfo).team_id : -1,
    { skip: selectedViewInfo?.view_type !== 'TeamView' }
  );
  const dispatch = useAppDispatch();
  const [updateTeam] = useUpdateTeamDataMutation();
  const [isTeamDataUpdating, setIsTeamDataUpdating] = React.useState(false);

  React.useEffect(() => {
    if (userViews) {
      handleSelectView(userViews.global_view_info);
    }
  }, [userViews]);

  function taskCompleted(success: boolean) {
    setIsTeamDataUpdating(false);
    dispatch(setDataRefreshed(true));
    dispatch(
      open({
        open: true,
        message: success ? 'Data Updated' : 'Data update failed.',
        severity: success ? 'success' : 'error'
      })
    );
  }

  async function handleUpdate() {
    setIsTeamDataUpdating(true);
    await updateTeam(selectedViewInfo?.view_type === 'TeamView' ? (selectedViewInfo as TeamViewInfo).team_id : -1)
      .unwrap()
      .then((val) => {
        if (val && val.task_id) {
          const task_id = val.task_id;
          run_task(task_id, () => {            
            taskCompleted(true);
          }, () => {
            taskCompleted(false);
          });
        } else {
          taskCompleted(false);
        }
      })
      .catch(() => {
        taskCompleted(false);
      });
  }

  function renderManagerToggle() {
    //handle toggle manager view
    const handleToggleManagerView = (event: React.ChangeEvent<HTMLInputElement>) => {
      dispatch(updateIsManagerView(event.target.checked));
    };
    //return component
    return (
      <Stack direction={'row'} alignItems={'center'}>
        <FormGroup>
          <FormControlLabel
            control={<Switch size="medium" checked={isManagerView} onChange={handleToggleManagerView} />}
            label="Manager View"
          />
        </FormGroup>
        {selectedViewInfo?.view_type === 'TeamView' ? (
          <Box sx={{ position: 'relative' }}>
            <Button
              variant="contained"
              sx={{ color: 'white' }}
              onClick={() => {
                handleUpdate();
              }}
              disabled={isTeamDataUpdating}
            >
              Refresh Data
            </Button>
            {isTeamDataUpdating && (
              <CircularProgress
                size={24}
                sx={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  marginTop: '-12px',
                  marginLeft: '-12px'
                }}
              />
            )}
            <Tooltip title="Team settings">
              <IconButton color="primary" onClick={() => props.onTeamSettingsClick((selectedViewInfo as TeamViewInfo).team_id)} sx={{margin:'0 0 0 1rem'}}>
                  <FaGear />
              </IconButton>
            </Tooltip>
          </Box>
        ) : null}
      </Stack>
    );
  }

  function getViewInfoDisplayName(viewInfo: ViewInfo | null) {
    if (viewInfo == null) {
      return '';
    }
    switch (viewInfo.view_type) {
      case 'GlobalView': {
        return 'Global';
      }

      case 'TeamView': {
        const teamViewInfo = viewInfo as TeamViewInfo;
        return teamViewInfo.team_name;
      }

      default: {
        return '';
      }
    }
  }

  function handleSelectView(viewInfo: ViewInfo) {
    dispatch(updateCurrentViewInfo(viewInfo));
    if (viewInfo?.view_type === 'TeamView') {
      const tv = viewInfo as TeamViewInfo;
      dispatch(
        setCurrentTeam({
          team_id: tv.team_id.toString(),
          team_name: tv.team_name
        } as Team));
    }
  }

  if (userViews) {
    return (
      <Stack
        direction={'row'}
        justifyContent={'space-between'}
        className=" w-[96%] mb-8 items-center bg-white lg:gap-8 gap-4 rounded-md p-3 shadow-sm shadow-gray-400"
      >
        <Select value={getViewInfoDisplayName(selectedViewInfo)} className=" w-64">
          <ListSubheader className=" text-sm">Global View</ListSubheader>
          {
            <MenuItem
              onClick={() => {
                handleSelectView(userViews.global_view_info);
              }}
              value={getViewInfoDisplayName(userViews.global_view_info)}
              key={userViews.global_view_info.id}
            >
              Global View
            </MenuItem>
          }

          <ListSubheader>Vertical Teams</ListSubheader>
          {userViews.team_view_infos.map((teamViewInfo, index) => {
            return (
              <MenuItem
                onClick={() => {
                  handleSelectView(teamViewInfo);
                }}
                value={getViewInfoDisplayName(teamViewInfo)}
                key={index}
              >
                {getViewInfoDisplayName(teamViewInfo)}
              </MenuItem>
            );
          })}

          <ListSubheader>Horizontal Teams</ListSubheader>
          {userViews.hor_team_view_infos.map((horTeamViewInfo, index) => {
            return (
              <MenuItem
                onClick={() => {
                  handleSelectView(horTeamViewInfo);
                }}
                value={getViewInfoDisplayName(horTeamViewInfo)}
                key={index}
              >
                {getViewInfoDisplayName(horTeamViewInfo)}
              </MenuItem>
            );
          })}
        </Select>
        <Stack direction={'row'} alignItems={'center'}>
          {selectedViewInfo && selectedViewInfo.permission_level > 2 ? renderManagerToggle() : null}
          {selectedViewInfo?.view_type === 'TeamView' ? (
            <Tooltip title={teamInfo ? "Last sync time from " + teamInfo.sprint_group_name + " (" + teamInfo.jira_board_id + ")" : "Last sync time"}>
              <div className="bg-gray-50 rounded-md shadow-inner shadow-gray-200 px-6 py-2 ml-6">
                {teamInfo ? moment.utc(teamInfo.last_updated).tz(moment.tz.guess()).format('YYYY/MM/DD HH:mm:ss') : 'Never'}
              </div>
            </Tooltip>
          ) : null}
        </Stack>
      </Stack>
    );
  } else if (isLoading) {
    return (
      <Stack justifyContent="center" alignItems="center" className=" w-[96%] mb-4 items-center">
        <CircularProgress size="4rem" />
      </Stack>
    );
  } else {
    return (
      <Typography variant="body1" color="red" className=" border-2 border-blue-500 w-[96%] mb-4 items-center">
        Error loading user view options.
      </Typography>
    );
  }
}

export default ViewSelector;
