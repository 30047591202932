import { Typography, Button } from '@mui/material';
import { Stack } from '@mui/system';
import SingleUserSelect from '../controls/SingleUserSelect';
import React, { useEffect } from 'react';
import { useDeleteTeamMutation, useSetNewManagerMutation } from '../redux/slices/teamApiSlice';
import { useAppDispatch } from '../redux/hooks';
import { open } from '../redux/slices/snackbarSlice';
import { updateCurrentViewInfo } from '../redux/slices/viewSelectorSlice';
import ConfirmModal from '../controls/ConfirmModal';
import { useGetAllUsersWithPermissionsQuery } from '../redux/slices/userApiSlice';
import { ManagerTransferBody, UserInTeam } from '../models/basic';

export interface ITeamSettingProps {
  teamId: number;
}

const TeamSetting = (props: ITeamSettingProps) => {
  const dispatch = useAppDispatch();
  const [selectedUsersError, setSelectedUsersError] = React.useState(false);
  const [selectedUser, setSelectedUser] = React.useState<UserInTeam | null>(null);
  const { data, error, isLoading } = useGetAllUsersWithPermissionsQuery({permissions:['MANAGER', 'ADMIN'], team_id: null});
  const [setNewManager] = useSetNewManagerMutation();
  const [deleteTeam] = useDeleteTeamMutation();
  const [isDeleteConfirmOpen, setIsDeleteConfirmOpen] = React.useState(false);

  // reset selected user when view changes
  useEffect(() => {
    setSelectedUser(null);
  }, [props.teamId]);

  async function handleSubmitNewManager() {
    // make sure a user is selected
    if (!selectedUser) {
      setSelectedUsersError(true);
      return;
    }
    const requestBody: ManagerTransferBody = {
      team_id: props.teamId,
      new_manager_id: selectedUser.id
    };

    // communicate to backend
    await setNewManager(requestBody).unwrap();

    // Show success snackbar
    dispatch(
      open({
        open: true,
        message: 'Transfer successful',
        severity: 'success'
      })
    );
    console.info('Edit successful');

    dispatch(updateCurrentViewInfo(null));
  }

  function handleAbortEditManager() {
    setSelectedUser(null);
  }

  async function handleDeleteTeam() {
    dispatch(updateCurrentViewInfo(null));
    await deleteTeam(props.teamId).unwrap();
    // Show success snackbar
    dispatch(
      open({
        open: true,
        message: 'Deletion successful',
        severity: 'success'
      })
    );
    console.info('Edit successful');

    dispatch(updateCurrentViewInfo(null));
  }

  return (
    <>
      <ConfirmModal
        title={'Delete team?'}
        message={'This action cannot be reversed.'}
        onConfirm={handleDeleteTeam}
        isOpen={isDeleteConfirmOpen}
        setIsOpen={setIsDeleteConfirmOpen}
      ></ConfirmModal>
      <Typography className=" mb-1">Transfer Ownership</Typography>
      <div className=" w-full flex mt-1">
        <SingleUserSelect
          selectedUser={selectedUser}
          selectedUserError={selectedUsersError}
          helperText={''}
          disabled={false}
          setSelectedUser={setSelectedUser}
          allUsers={data}
          isErrorGettingUsers={error}
          isLoadingUsers={isLoading}
          label="Select a Manager"
        />

        <Stack direction={'row'} justifyContent={'flex-end'} spacing={2} className=" mt-4">
          <Button variant="outlined" color="error" disabled={!selectedUser} onClick={handleAbortEditManager}>
            Cancel
          </Button>
          <Button variant="outlined" color="success" disabled={!selectedUser} onClick={handleSubmitNewManager}>
            Submit
          </Button>
        </Stack>
      </div>

      <div className=" flex w-full justify-center mt-10 shadow-inner shadow-slate-300 bg-gray-50 p-4 rounded-md border-double">
        <Button
          variant="contained"
          color="error"
          className=" w-full"
          onClick={() => {
            setIsDeleteConfirmOpen(true);
          }}
        >
          DELETE TEAM
        </Button>
      </div>
    </>
  );
};

export default TeamSetting;
