import { ValueOptions } from "@mui/x-data-grid";

export enum PermissionLevel {
  SELF_USER = 1,
  SELF_TEAM = 2,
  ALL_TEAMS = 8,
  VIEW_USER = 16,
  VIEW_TEAM = 32,
  VIEW_STATS = 64,
  MANAGE_USER = 128,
  MANAGE_TEAM = 256,  
  MANAGE_USER_BASIC_DATA = 512,
  MANAGE_GLOBAL_BASIC_DATA = 1024,
  MANAGE_SETTINGS = 2048,

  GRANT_PERMISSION = 1048576, // 2 power of 20

  USER = SELF_USER | VIEW_USER | MANAGE_USER,
  MANAGER = USER | SELF_TEAM | MANAGE_USER | VIEW_TEAM | MANAGE_TEAM | VIEW_STATS | MANAGE_USER_BASIC_DATA | MANAGE_SETTINGS | GRANT_PERMISSION,
  ADMIN = MANAGER | ALL_TEAMS | MANAGE_GLOBAL_BASIC_DATA,
  GLOBAL_VIEWER = USER | SELF_TEAM | ALL_TEAMS | VIEW_TEAM | VIEW_STATS,
}

export const permissionLevelOptions: ValueOptions[] = [
  { label: "User", value: PermissionLevel.USER },
  { label: "Global Viewer", value: PermissionLevel.GLOBAL_VIEWER },
  { label: "Manager", value: PermissionLevel.MANAGER },
  { label: "Admin", value: PermissionLevel.ADMIN },
]

type PermissionLevelNames = keyof typeof PermissionLevel;

export function getPermissionLevelByName(name: PermissionLevelNames): PermissionLevel | undefined {
  if (Object.keys(PermissionLevel).includes(name)) {
    return PermissionLevel[name] as PermissionLevel;
  }
  return undefined;
}

export function getPermissionLevelByValue(value: number): PermissionLevel | undefined {
  if (Object.values(PermissionLevel).includes(value)) {
    return value as PermissionLevel;
  }
  return undefined;
}

export function getPermissionLevelName(value: number): PermissionLevelNames | undefined {
  const pl = getPermissionLevelByValue(value);
  return pl === undefined ? undefined : PermissionLevel[pl] as PermissionLevelNames;
}

export function getPermissionLevelValue(name: PermissionLevelNames): number | undefined {
  const pl = getPermissionLevelByName(name);
  return pl === undefined ? undefined : pl;
}
