import React from 'react';
import { Divider, Stack, Typography } from '@mui/material';
import TeamSprintUserDataField from './TeamSprintUserDataField';
import { Sprint } from '../models/basic';
import { UserSprintStatsForUserView } from '../models/stats';

interface ITeamSprintUserProps {
  sprints: Sprint[];
  userSprintStats: UserSprintStatsForUserView;
  highlightedRowKey: string;
  setHighlightedRowKey: React.Dispatch<React.SetStateAction<string>>;
}

function UserSprintUser(props: ITeamSprintUserProps) {
  return (
    <Stack direction={'column'} className=" w-fit py-2 mx-2 ">
      <div className="flex flex-col items-center justify-center h-20 px-3 bg-white rounded-md shadow-sm shadow-gray-300 border-navman_green border-b-2 mb-1">
        <Typography variant="subtitle1" className="text-center">
          {props.userSprintStats.display_name}
        </Typography>
        <div className="flex w-fit py-0.5 mt-1 rounded-md shadow-inner bg-gray-50 shadow-slate-200">
          <Typography variant="caption" className=" w-20 text-center">
            SP
          </Typography>
          <Divider orientation="vertical" flexItem />
          <Typography variant="caption" className=" w-20 text-center">
            Days
          </Typography>
        </div>
      </div>

      <div className=" flex flex-col items-center justify-center px-3 bg-white rounded-md shadow-sm shadow-gray-300 py-1 hover:shadow-lg transition-all">
        {props.sprints.map((sprint, index) => {
          try {
            const userSprintInfo = props.userSprintStats.sprints[sprint.sprint_id.toString()];
            return (
              <div
                key={index}
                className={`flex w-fit justify-center items-center h-9 rounded-md shadow-inner bg-gray-50 shadow-gray-100 my-1 transition-colors
                ${
                  props.highlightedRowKey == sprint.sprint_id.toString()
                    ? 'bg-navman_green_grey_highlight shadow-none'
                    : 'bg-gray-50 shadow-inner'
                }`}
                onMouseEnter={() => {
                  props.setHighlightedRowKey(sprint.sprint_id.toString());
                }}
                onMouseLeave={() => {
                  props.setHighlightedRowKey('');
                }}
              >
                <Typography variant="caption" className=" w-20 text-center">
                  {userSprintInfo.sp_total}
                </Typography>
                <Typography variant="caption" className=" w-20 text-center">
                  {userSprintInfo.days_available}
                </Typography>
              </div>
            );
          } catch (error) {
            return <div className="flex h-9 my-1"></div>;
          }
        })}
        <div className=" h-5"></div>

        <TeamSprintUserDataField
          highlightedRowKey={props.highlightedRowKey}
          setHighlightedRowKey={props.setHighlightedRowKey}
          displayedData={props.userSprintStats.sp_per_day.toFixed(1)}
          rowKey={'sp_per_day'}
        />

        <TeamSprintUserDataField
          highlightedRowKey={props.highlightedRowKey}
          setHighlightedRowKey={props.setHighlightedRowKey}
          displayedData={`${(props.userSprintStats.ratioed_by_top_performer * 100).toFixed(0)}%`}
          rowKey={'ratioed_by_top_performer'}
        />

        <TeamSprintUserDataField
          highlightedRowKey={props.highlightedRowKey}
          setHighlightedRowKey={props.setHighlightedRowKey}
          displayedData={props.userSprintStats.last_sprint_sp_per_day.toFixed(1)}
          rowKey={'last_sprint_sp_per_day'}
        />

        <TeamSprintUserDataField
          highlightedRowKey={props.highlightedRowKey}
          setHighlightedRowKey={props.setHighlightedRowKey}
          displayedData={props.userSprintStats.difficulty_r2.toFixed(1)}
          rowKey={'difficulty_r2'}
        />
      </div>
    </Stack>
  );
}

export default UserSprintUser;
