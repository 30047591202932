import React from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Box, Collapse, Modal, Stack, Typography } from '@mui/material';
import ViewSelector from '../components/ViewSelector';
import Scheduler from '../components/Scheduler';
import { useAppSelector } from '../redux/hooks';
import TeamEditor from '../components/TeamEditor';
import TeamSetting from '../components/TeamSetting';
import ProfileView from '../components/ProfileView';
import { useGetViewComponentsQuery } from '../redux/slices/userApiSlice';
import { MdArrowDropDown } from 'react-icons/md';
import PersonalLeaveView from '../components/PersonalLeaveView';
import TeamSprintsView from '../components/TeamSprintsView';
import UserSprintsView from '../components/UserSprintsView';
import TeamVelocityView from '../components/TeamVelocityView';
import TeamSettingsForm from '../components/TeamSettingsForm';
import { TeamViewInfo } from '../models/view';

interface ComponentDictionary {
  [key: string]: (props: any) => JSX.Element;
}

const componentDictionary: ComponentDictionary = {
  'Leave Schedule': (props) => <Scheduler {...props} />,
  'Team Editor': (props) => (
    <Collapse in={props.isManager} unmountOnExit mountOnEnter>
      <div>
        <TeamEditor {...props} />
      </div>
    </Collapse>
  ),
  'Team Setting': (props) => (
    <Collapse in={props.isManager} unmountOnExit mountOnEnter>
      <div>
        <TeamSetting {...props} />
      </div>
    </Collapse>
  ),
  'Profile View': (props) => <ProfileView {...props} />,
  'Personal Leave': (props) => <PersonalLeaveView {...props} />,
  'Team Sprints View': (props) => <TeamSprintsView {...props} />,
  'Team Velocity View': (props) => <TeamVelocityView {...props} />,
  'User Sprints View': (props) => <UserSprintsView {...props} />
  // Add other components as needed
};

function Home() {
  const currentViewInfo = useAppSelector((state) => state.viewSelector.currentViewInfo);
  const isManagerView = useAppSelector((state) => state.viewSelector.isManagerView);
  const { data } = useGetViewComponentsQuery(
    !currentViewInfo
      ? null
      : currentViewInfo.view_type === 'GlobalView'
      ? null
      : (currentViewInfo as TeamViewInfo).team_id
  );
  const [openTeamSettingsFormModal, setOpenTeamSettingsFormModal] = React.useState(false);

  function onTeamSettingsClick(teamId: number) {
    setOpenTeamSettingsFormModal(!openTeamSettingsFormModal);
  }

  function handleClose() {
    setOpenTeamSettingsFormModal(false);
  }

  return (
    <Stack className="w-full flex items-center min-h-screen px-4 py-8 bg-gray-50">
      <ViewSelector onTeamSettingsClick={onTeamSettingsClick} />
      {!currentViewInfo ? (
        <div key={['hintText', 0].join()}>Select a team</div>
      ) : (
        <div className=" w-[96%] shadow-inner shadow-slate-400 rounded-md bg-gray-50 py-2 px-4">
          {!data
            ? null
            : data['user_components']
                .map((componentName, index) => {
                  const Component = componentDictionary[componentName];
                  // pass teamId to every component if we are rendering team view components
                  if (currentViewInfo.view_type === 'TeamView') {
                    return Component ? (
                      <div className=" w-full my-2" key={index}>
                        <Accordion>
                          <AccordionSummary expandIcon={<MdArrowDropDown />}>{componentName}</AccordionSummary>
                          <AccordionDetails>
                            {Component({})}
                          </AccordionDetails>
                        </Accordion>
                      </div>
                    ) : null;
                  } else {
                    return Component ? (
                      <div className=" w-full my-2" key={index}>
                        <Accordion>
                          <AccordionSummary expandIcon={<MdArrowDropDown />}>{componentName}</AccordionSummary>
                          <AccordionDetails>{Component({})}</AccordionDetails>
                        </Accordion>
                      </div>
                    ) : null;
                  }
                })
                .concat(
                  data['manager_components'].map((componentName, index) => {
                    const Component = componentDictionary[componentName];
                    // pass teamId to every component if we are rendering team view components
                    if (currentViewInfo.view_type === 'TeamView') {
                      return Component ? (
                        <Collapse in={isManagerView} unmountOnExit mountOnEnter key={['team', index].join()}>
                          <div className=" w-full my-2">
                            <Accordion>
                              <AccordionSummary expandIcon={<MdArrowDropDown />}>{componentName}</AccordionSummary>
                              <AccordionDetails>
                                {Component({
                                  isManager: isManagerView,
                                  teamId: (currentViewInfo as TeamViewInfo).team_id
                                })}
                              </AccordionDetails>
                            </Accordion>
                          </div>
                        </Collapse>
                      ) : null;
                    } else {
                      console.log(currentViewInfo.view_type);
                      return Component ? (
                        <Collapse in={isManagerView} unmountOnExit mountOnEnter key={index}>
                          <div className=" w-full my-2">
                            <Accordion>
                              <AccordionSummary expandIcon={<MdArrowDropDown />}>{componentName}</AccordionSummary>
                              <AccordionDetails>{Component({ isManager: isManagerView })}</AccordionDetails>
                            </Accordion>
                          </div>
                        </Collapse>
                      ) : null;
                    }
                  })
                )}

            <Modal open={openTeamSettingsFormModal} onClose={handleClose}>
              <Box className="model-box">
                <Stack>
                  <Typography>Team settings for {(currentViewInfo as TeamViewInfo).team_name}</Typography>
                  <TeamSettingsForm teamId={(currentViewInfo as TeamViewInfo).team_id} onSave={handleClose} onClose={handleClose} />
                </Stack>
              </Box>
            </Modal>
          </div>
        )}
    </Stack>
  );
}

export default Home;
