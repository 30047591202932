import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { useGetGlobalPermissionQuery } from '../redux/slices/authApiSlice';
import { CircularProgress } from '@mui/material';
import { PermissionLevel } from '../models/permissionLevels';
import { hasAnyPermission } from './auth';

/**
 * This is a modified version of AuthOutlet that redirects to /login if the user is not logged in.
 * @param permissionLevels An array of permission levels that are allowed to access the outlet. Any one of the permission in the list would pass the permision check.
 * @returns the outlet if the user is logged in and has the required permission level, otherwise redirects to /login.
 */
export function AuthOutlet({ permissionLevels }: { permissionLevels: PermissionLevel[] }) {
  const location = useLocation();
  const { data: userPermission, isError, isLoading } = useGetGlobalPermissionQuery();

  function hasPermission() {
    return hasAnyPermission(permissionLevels, userPermission);
  }

  return isLoading ? (
    <div className=" h-screen w-full flex items-center justify-center">
      <CircularProgress size="16rem" />
    </div>
  ) : isError ? (
    <Navigate to="/login" state={{ from: location }} replace />
  ) : (
    hasPermission() ? (
      <Outlet />
    ) : (
      <Navigate to="/forbidden" state={{ from: location }} replace />
    )
  );
}
