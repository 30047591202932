import React from 'react';
import { Typography } from '@mui/material';

interface ITeamSprintUserDataFieldProps {
  highlightedRowKey: string;
  setHighlightedRowKey: React.Dispatch<React.SetStateAction<string>>;
  displayedData: string;
  rowKey: string;
}

function TeamSprintUserDataField(props: ITeamSprintUserDataFieldProps) {
  return (
    <div
      className={`flex w-full justify-center items-center h-9 rounded-md shadow-gray-100 my-1 transition-colors
    ${
      props.highlightedRowKey == props.rowKey ? 'bg-navman_green_grey_highlight shadow-none' : 'bg-gray-50 shadow-inner'
    }`}
      onMouseEnter={() => {
        props.setHighlightedRowKey(props.rowKey);
      }}
      onMouseLeave={() => {
        props.setHighlightedRowKey('');
      }}
    >
      <Typography variant="caption" className=" content-stretch text-center">
        {props.displayedData}
      </Typography>
    </div>
  );
}

export default TeamSprintUserDataField;
