import { BaseQueryFn, FetchArgs, FetchBaseQueryError, createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { clearJwtToken, getJwtToken, setJwtToken } from '../../utils/auth';
import { open } from './snackbarSlice';
import { isFetchBaseQueryError } from '../../utils/helpers';
import { get_settings } from '../../settings';

const BASE_URL = get_settings()['APP_API_URL'];
const JWT_ENABLE_AUTO_REFRESH_TOKEN = get_settings()['JWT_ENABLE_AUTO_REFRESH_TOKEN'];

const baseQuery = fetchBaseQuery({
    baseUrl: BASE_URL,
    timeout: 10 * 60 * 1000, // 10 minutes
    prepareHeaders: (headers, { getState }) => {
        // By default, if we have a token in the store, let's use that for authenticated requests
        const token = getJwtToken();
        if (token && token.length > 0) {
            headers.set('Authorization', `Bearer ${token}`)
        }
        return headers
    }
})

export const baseQueryWithError: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (args, api, extraOptions = {}) => {
    const result = await baseQuery(args, api, extraOptions);

    if (JWT_ENABLE_AUTO_REFRESH_TOKEN) {
        const token = result.meta?.response?.headers.get('token');
        if (token && token.length > 0) {
            setJwtToken(token);
        }
    }

    const err = result?.error;
    if (isFetchBaseQueryError(err)) {
        if (err.status === 401) { // 401
            clearJwtToken();
            if (window) {
                window.location.href = `/login?redirect=${window.location.pathname}`;
            }
        }
        else if (err.status === 403) {
            api.dispatch(open({
                open: true,
                message: 'You are not authorized to access the resource.',
                severity: 'error'
            }));
        } else {
            const errMsg = err.data ? 'Error: ' + JSON.stringify(err.data) : 'Error occurred at remote service.';
            // Show error snackbar
            api.dispatch(
                open({
                    open: true,
                    message: errMsg,
                    severity: 'error'
                })
            );
        }
    }
    return result;
};

export const cmtApi = createApi({
    reducerPath: 'cmtApi',
    baseQuery: baseQueryWithError,
    tagTypes: ['User', 'Users', 'Team', 'Position', 'PublicHolidays', 'UserViewInfos', 'ViewComponents', 'Leaves', 'GlobalPermission', 'Stats', 'Settings'],
    endpoints: () => ({})
});
