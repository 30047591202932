import { StringDecoder } from 'string_decoder';
import { Country, CountryTimezone, User } from '../../models/basic';
import { getPermissionLevelName } from '../../models/permissionLevels';
import { UserGlobalPermissionView, UserViewInfos, ViewComponents } from '../../models/view';
import { cmtApi } from './apiSlice';

export const teamApiSlice = cmtApi.injectEndpoints({
    endpoints: builder => ({
        getAllUsers: builder.query<User[], void>({
            query: () => `users`,
            providesTags: ["User"]
        }),
        getAllUsersWithPermissions: builder.query<User[], any>({
            query: ({permissions, team_id}) => ({
                url: `users?permission=${permissions.join(',')}&team_id=${(team_id ?? '')}`,
                method: "GET",
            }), providesTags: ["User"]
        }),
        getUserViews: builder.query<UserViewInfos, void>({
            query: () => ({
                url: `users/views`
            }),
            providesTags: ["Team"]
        }),
        getViewComponents: builder.query<ViewComponents, number | null>({
            query: (team_id) => ({
                url: team_id ? `users/view-components?team_id=${team_id}` : `users/view-components`,
                method: "GET"
            }),
            providesTags: ["ViewComponents"]
        }),
        getProfile: builder.query<User, void>({
            query: () => `users/profile`,
            providesTags: ["User"]
        }),
        getUsers: builder.query<UserGlobalPermissionView[], void>({
            query: () => `users/global-permissions`,
            transformResponse: (response: UserGlobalPermissionView[]) => {
                return response.map((user) => {
                    return {
                        ...user,
                        country: user.country ? user.country : "Select a Country",
                        permission_level: user.permission_level
                    }
                })
            }, providesTags: ["Users"]
        }),
        setUserCountryTimezone: builder.mutation<User, { user_id: string, country_timezone: CountryTimezone }>({
            query: ({ user_id, country_timezone }) => ({
                url: `users/${user_id}/country-timezone`,
                method: 'PUT',
                body: { country_timezone },
            }),
            invalidatesTags: ["Users", "User", "PublicHolidays", "Leaves"]
        }),
        getAllTimezones: builder.query<CountryTimezone[], void>({
            query: () => `timezones`,
        }),
        getAllCountries: builder.query<Country[], void>({
            query: () => `countries`,
        }),
    })
})

export const {
    useGetAllUsersQuery,
    useGetUserViewsQuery,
    useGetProfileQuery,
    useGetUsersQuery,
    useGetAllCountriesQuery,
    useSetUserCountryTimezoneMutation,
    useGetAllUsersWithPermissionsQuery,
    useGetViewComponentsQuery,
    useGetAllTimezonesQuery,
} = teamApiSlice;