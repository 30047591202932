import React from 'react';
import {
  Autocomplete,
  CircularProgress,
  Stack,
  TextField,
  Typography,
  FormControl,
  Switch,
  FormControlLabel,
  Tooltip
} from '@mui/material';
import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { User, UserInTeam } from '../models/basic';

interface ISingleUserSearchProps {
  selectedUser: UserInTeam | null;
  selectedUserError: boolean;
  helperText: string;
  disabled: boolean;
  setSelectedUser: (selectedUser: UserInTeam) => void;
  allUsers: User[] | undefined;
  isErrorGettingUsers: FetchBaseQueryError | SerializedError | undefined;
  isLoadingUsers: boolean;
  label: string;
}

/**
 * This component is a search bar that allows the user to select a single user.
 * @param props the props for this component.
 * @returns the SingleUserSearch component.
 */
const SingleUserSelect = (props: ISingleUserSearchProps) => {
  const [searchByEmail, setSearchByEmail] = React.useState(false);
  const {
    selectedUser,
    selectedUserError,
    helperText,
    disabled,
    setSelectedUser,
    allUsers,
    isErrorGettingUsers,
    isLoadingUsers
  } = props;

  const handleSelectedValuesChange = (event: React.SyntheticEvent, value: User | null) => {
    // Check if the value is not null before converting it to a UserInTeam object
    if (value) {
      const user: UserInTeam = {
        id: value.id,
        display_name: value.display_name,
        email: value.email,
        country_timezone: value.country_timezone,
        permission_level: value.permission_level,
        experience: 'Select a value',
        role: 'Select a value',
        team_id: ''
      };
      setSelectedUser(user);
    }
  };

  // Handle the switch to search by email
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchByEmail(event.target.checked);
  };

  return (
    <>
      {isErrorGettingUsers ? (
        <Typography variant="body1" color="red">
          Error loading users
        </Typography>
      ) : isLoadingUsers ? (
        <Stack justifyContent="center" alignItems="center">
          <CircularProgress size="4rem" />
        </Stack>
      ) : allUsers ? (
        <>
          <Stack direction="row" sx={{ width: '100%' }} justifyContent="space-evenly" alignItems="center">
            <Autocomplete
              id="user-autocomplete"
              sx={{ width: '70%' }}
              value={selectedUser}
              options={allUsers}
              disabled={disabled}
              getOptionLabel={(option) => (searchByEmail ? `${option.email}` : `${option.display_name}`)}
              onChange={handleSelectedValuesChange}
              filterSelectedOptions
              renderInput={(params) => (
                <Tooltip
                  title={disabled ? 'Only admins can select managers other than themselves' : null}
                  placement="bottom"
                  arrow
                >
                  <TextField {...params} label={props.label} error={selectedUserError} helperText={helperText} />
                </Tooltip>
              )}
              isOptionEqualToValue={(option, value) => {
                return option.id === value.id;
              }}
            />

            <FormControl sx={{ width: '30%' }}>
              <FormControlLabel
                control={<Switch checked={searchByEmail} onChange={handleChange} />}
                sx={{ '& .MuiFormControlLabel-label': { fontSize: '0.9rem' } }}
                label="Email Search"
                labelPlacement="bottom"
              />
            </FormControl>
          </Stack>
        </>
      ) : null}
    </>
  );
};

export default SingleUserSelect;
