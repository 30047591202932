import * as React from 'react';
import {
  Button,
  FormControl,
  Grid,
  Stack,
  TextField,
  Typography
} from '@mui/material';
import { useAppDispatch } from '../redux/hooks';
import { open } from '../redux/slices/snackbarSlice';
import { setSettingsChanged } from '../redux/slices/appStateSlice';
import { useGetTeamSettingsQuery, useSetTeamSettingsMutation } from '../redux/slices/settingsApiSlice';
import { SettingItem } from '../models/settings';
import { updateListItem } from '../utils/helpers';

interface TeamSettingsFormProps {
  teamId: number,
  onSave: () => void;
  onClose: () => void;
}

const TeamSettingsForm = (props: TeamSettingsFormProps) => {
  const dispatch = useAppDispatch();
  const { data: settings, error, isLoading } = useGetTeamSettingsQuery(props.teamId, { refetchOnMountOrArgChange: true });
  const [setSetting, { isLoading: isSubmitLoading }] = useSetTeamSettingsMutation();
  const [data, setData] = React.useState([] as SettingItem[]);

  React.useEffect(() => {
    setData(settings || []);
  }, [settings]);

  function setSettingValue(row: any, val: string) {
    if (!val || val.length <= 0) {
      updateListItem<SettingItem>(setData, it => it.key === row.key, it => {
        return { value: val, invalid: true, error: it.description + ' is required.' };
      });
      return false;
    }

    updateListItem<SettingItem>(setData, it => it.key === row.key, it => {
      return { value: val, invalid: false, error: '' };
    });
    return true;
  }

  async function handleSubmit() {
    const xSprintOfList = data.find(x => x.key === 'NUM_OF_SPRINTS_FOR_LIST') as SettingItem;
    const xSprintOfVelocity = data.find(x => x.key === 'NUM_OF_SPRINTS_FOR_VELOCITY') as SettingItem;
    if (xSprintOfList.value && xSprintOfVelocity.value && parseInt(xSprintOfList.value) < parseInt(xSprintOfVelocity.value)) {
      updateListItem<SettingItem>(setData, it => it.key === 'NUM_OF_SPRINTS_FOR_VELOCITY', it => {
        return { invalid: true, error: 'value of "' + xSprintOfVelocity.description + '" should be less than or equals to "' + xSprintOfList.description + '"' };
      });
      return false;
    }

    if (data.some(x => x.invalid))
      return false;

    await setSetting({ team_id: props.teamId, data: data }).unwrap();
    props.onSave();
    dispatch(setSettingsChanged(true));
    setTimeout(() => {
      dispatch(setSettingsChanged(false));
    }, 3000);
    // Show success snackbar and navigate to the team page
    dispatch(
      open({
        open: true,
        message: 'Team settings saved successfully',
        severity: 'success'
      })
    );
  }

  return (
    <Stack spacing={2}>
      {isLoading && <div>Loading...</div>}
      {settings && (
        <>
          <Grid container spacing={2} sx={{ width: '100%' }}>
            {data.map(row => {
              return (
                <Grid item xs={6} key={row.key}>
                  <FormControl fullWidth>
                    <label>{row.description}</label>
                    <TextField
                      variant="outlined"
                      type={row.inputType}
                      value={row.value}
                      required={true}
                      error={row.invalid}
                      helperText={row.error}
                      onChange={(e) => setSettingValue(row, e.target.value)}
                    />
                  </FormControl>
                </Grid>
              );
            })}
          </Grid>
          <Stack direction={'row'}>
            <div style={{ flexGrow: '1' }}></div>
            <Button variant="contained" sx={{ color: 'white', margin: '0 8px' }} onClick={handleSubmit}>
              Save
            </Button>
            <Button variant="contained" color="secondary" sx={{ color: 'white', margin: '0 8px' }} onClick={props.onClose}>
              Close
            </Button>
          </Stack>
        </>
      )}
    </Stack>
  );
};


export default TeamSettingsForm;