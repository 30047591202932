import { getTeamSprintStats, getTeamVelocity, getUserSprintStats } from "../../datamapper/stats";
import { ManagerTransferBody, Role, SprintGroup, Team, TeamEditBody, TeamInit, TeamSprintComment, UserInTeam } from "../../models/basic";
import { UserLeaves } from "../../models/leave";
import { TeamSprintStatsViewResponse, TeamVelocity, UserSprintStatsViewResponse } from "../../models/stats";
import { cmtApi } from "./apiSlice";


export const teamApiSlice = cmtApi.injectEndpoints({
    endpoints: builder => ({
        getSprintGroups: builder.query<SprintGroup[], void>({
            query: () => ({
                url: `team/sprint_groups`,
                method: 'GET',
            })
        }),
        addTeam: builder.mutation<any, TeamInit>({
            query: (body) => ({
                url: `team`,
                method: 'POST',
                body,
            }), invalidatesTags: ["Team"],
        }),
        getTeamRoles: builder.query<string[], number>({
            query: (teamId) => {
                return {
                    url: `team/${teamId}/roles`,
                    method: 'GET'
                };
            }, providesTags: ["Team"]
        }),
        editPositions: builder.mutation<any, TeamEditBody>({
            query: (body) => ({
                url: `team/${body.team_id}`,
                method: 'PUT',
                body
            }), invalidatesTags: ["Position", "Leaves", "Stats"],
        }),
        getPositions: builder.query<UserInTeam[], number>({
            query: (teamId) => {
                return {
                    url: `team/${teamId}/positions`,
                    method: 'GET'
                };
            }, providesTags: ["Position"]
        }),
        setNewManager: builder.mutation<void, ManagerTransferBody>({
            query: (body) => ({
                url: `team/${body.team_id}/${body.new_manager_id}`,
                method: 'PUT',
            }), invalidatesTags: ["Position", "Team"]
        }),
        getTeamLeaves: builder.query<UserLeaves[], number>({
            query: (teamId) => ({
                url: `team/${teamId}/leaves`,
                method: 'GET',
            }), providesTags: ["Leaves"]
        }),
        deleteTeam: builder.mutation<void, number>({
            query: (teamId) => ({
                url: `team/${teamId}`,
                method: 'DELETE'
            }), invalidatesTags: ["Team", "Position", "Stats"]
        }),
        getTeamStats: builder.query<TeamSprintStatsViewResponse, number>({
            query: (teamId) => ({
                url: `/team/${teamId}/stats`,
                method: 'GET'
            }), 
            transformResponse: (response) => getTeamSprintStats(response), 
            providesTags: ["Stats"]
        }),
        getTeamInfo: builder.query<any, number>({
            query: (teamId) => ({
                url: `/team/${teamId}`,
                method: 'GET'
            }), providesTags: ["Stats"]
        }),
        updateTeamData: builder.mutation<any, number | null>({
            query: (teamId) => ({
                url: `/team/${teamId}/update_data`,
                method: 'POST'
            }), invalidatesTags: ["Stats"]
        }),
        getUserSprintStats: builder.query<UserSprintStatsViewResponse, any>({
            query: ({teamId, userId}) => ({
                url: `/team/${teamId}/user_stats?user_id=${userId ?? ''}`,
                method: 'GET'
            }),
            transformResponse: (response) => getUserSprintStats(response), 
            providesTags: ["Stats"]
        }),
        getTeamVelocity: builder.query<TeamVelocity[], number>({
            query: (teamId) => ({
                url: `/team/${teamId}/velocity`,
                method: 'GET'
            }),
            transformResponse: (response) => getTeamVelocity(response), 
            providesTags: ["Stats"]
        }),
        setTeamSprintCommittedSp: builder.mutation<void, any>({
            query: (body) => ({
                url: `/team/set_team_sprint_committedsp`,
                method: 'POST',
                body
            }), invalidatesTags: ["Team"]
        }),
        setTeamSprintComment: builder.mutation<void, TeamSprintComment>({
            query: (body) => ({
                url: `/team/set_team_sprint_comment`,
                method: 'POST',
                body
            }), invalidatesTags: ["Team"]
        }),
    })
})

export const {
    useAddTeamMutation,
    useEditPositionsMutation,
    useGetTeamRolesQuery,
    useGetPositionsQuery,
    useSetNewManagerMutation,
    useGetTeamLeavesQuery,
    useDeleteTeamMutation,
    useGetSprintGroupsQuery,
    useGetTeamStatsQuery,
    useGetTeamInfoQuery,
    useUpdateTeamDataMutation,
    useGetUserSprintStatsQuery,
    useGetTeamVelocityQuery,
    useSetTeamSprintCommittedSpMutation,
    useSetTeamSprintCommentMutation
 } = teamApiSlice;