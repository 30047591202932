import { get_settings } from "../settings";

const apiUrl = get_settings().APP_API_URL;

export function run_task(task_id: string, on_complete: () => void, on_error: (error?: any) => void, interval: number = 3000){
    // This function will run the task with the given task_id
    // and call on_complete when the task is complete
    console.log(`Running task ${task_id}`);
    // Simulate the task taking 2 seconds to complete
    const timer = setInterval(() => {        
        fetch(`${apiUrl}/task/${task_id}`, {method: 'GET'})
        .then(response => response.json())
        .then(data => {
            console.log(data.status); // 'PENDING', 'FAILURE', 'SUCCESS'
            if (data.status === 'SUCCESS') {
                clearInterval(timer);
                on_complete();
            } else if (data.status === 'FAILURE') {
                clearInterval(timer);
                on_error();   
            }
        })
        .catch(error => {
            clearInterval(timer);
            on_error(error);            
        });
    }, interval);
}