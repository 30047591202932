import { Leave, LeavesCreationBody, PublicHoliday } from '../../models/leave';
import { cmtApi } from './apiSlice';

export const leaveApiSlice = cmtApi.injectEndpoints({
    endpoints: builder => ({
        getPublicHolidays: builder.query<PublicHoliday[], void>({
            query: () => '/holidays',
            providesTags: ['PublicHolidays'], // Tag for getPublicHolidays query
        }),
        addPublicHoliday: builder.mutation<PublicHoliday, Partial<PublicHoliday>>({
            query: body => ({
                url: '/holiday',
                method: 'POST',
                body,
            }),
            invalidatesTags: ['PublicHolidays', 'Leaves'], // Invalidate PublicHolidays tag on success
        }),
        deletePublicHoliday: builder.mutation<void, number>({
            query: id => ({
                url: `/holiday/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['PublicHolidays', 'Leaves'], // Invalidate PublicHolidays tag on success
        }),
        refreshPublicHolidays: builder.mutation<void, void>({
            query: () => ({
                url: '/holidays',
                method: 'POST',
            }),
            invalidatesTags: ['PublicHolidays', 'Leaves'], // Invalidate PublicHolidays tag on success
        }),
        addLeave: builder.mutation<void, LeavesCreationBody>({
            query: body => ({
                url: `/users/${body.user_id}/leave`,
                method: 'POST',
                body,
            }),
            invalidatesTags: ['Leaves'], // Invalidate Leaves tag on success
        }),
        deleteLeave: builder.mutation<void, number>({
            query: id => ({
                url: `/leaves/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['Leaves'], // Invalidate Leaves tag on success
        }),
        refreshLeave: builder.mutation<void, void>({
            query: () => ({
                url: '/leave',
                method: 'POST',
            }),
            invalidatesTags: ['Leaves'], // Invalidate Leaves tag on success
        }),
        getLoggedInUsersLeaves: builder.query<Leave[], void>({
            query: () => '/leaves',
            providesTags: ['Leaves'],
        }),
    })
})



export const
    {
        useGetPublicHolidaysQuery,
        useAddPublicHolidayMutation,
        useDeletePublicHolidayMutation,
        useRefreshPublicHolidaysMutation,
        useAddLeaveMutation,
        useDeleteLeaveMutation,
        useRefreshLeaveMutation,
        useGetLoggedInUsersLeavesQuery
    } = leaveApiSlice;