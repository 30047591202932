import { Sprint, Team } from "../models/basic";
import { SprintStats, SprintStoryPointStats, TeamSprintStatsViewResponse, TeamVelocity, UserSprint, UserSprintStats, UserSprintStatsForUserView, UserSprintStatsViewResponse, UserSprintWithR2 } from "../models/stats";

export function getTeamSprintStats(data: any): TeamSprintStatsViewResponse {
    const team: Team = 'team' in data ? data['team'] : {};
    const user_sprint_stats: any[] = 'user_sprint_stats' in data ? data['user_sprint_stats'] : [];
    const sprints: Sprint[] = user_sprint_stats
        .flatMap(it => (it['sprints'] as any[]).map(o => o['sprint']))
        .reduce((items, cv) =>  {
            if (items.filter((x: { [x: string]: any; }) => x['sprint_id'] === cv['sprint_id']).length <= 0) {
                items.push(cv as Sprint);
            }
            return items;
        }, []);
    const users: UserSprintStats[] = user_sprint_stats
        .flatMap(it => {
                return {
                    "user_id": it["user"]["user_id"],
                    "display_name": it["user"]["display_name"],
                    "sp_per_day": it["sp_per_day"],
                    "team_avg_sp_per_day": data["team_avg_sp_per_day"],
                    "percent_diff_from_avg": it["percent_diff_from_avg"],
                    "ratioed_by_top_performer": it["ratioed_by_top_performer"],
                    "last_sprint_sp_per_day": it["last_sprint_sp_per_day"],
                    "last_sprint_improv_on_team_avg": it["last_sprint_improv_on_team_avg"],
                    "percent_of_team_sp": it["percent_of_team_sp"],
                    "difficulty_r2": it["difficulty_r2"],
                    "sprints": (it['sprints'] as any[]).reduce((sp, o) => {
                        sp[o['sprint']['sprint_id']] = {
                            "sp_total": o["sp_total"],
                            "days_available": o["days_available"],
                            "jql": o["jql"]
                        } as UserSprint;
                        return sp;
                    }, {} as { [sprint_id: string]: UserSprint })
                } as UserSprintStats
            });
    const sprint_stats: SprintStats[] = 'sprint_stats' in data ? data['sprint_stats'] : [];
    return { "team": team, "sprints": sprints, "users": users, "sprint_stats": sprint_stats } as TeamSprintStatsViewResponse;
}

export function getUserSprintStats(data: any): UserSprintStatsViewResponse {
    const user_sprint_stats: any[] = 'user_sprint_stats' in data ? data['user_sprint_stats'] : [];
    const story_points = 'story_points' in data ? data['story_points'] : [];
    const sprints: Sprint[] = 'sprints' in data ? data['sprints'] : user_sprint_stats
        .flatMap(it => (it['sprints'] as any[]).map(o => o['sprint']))
        .reduce((items, cv) =>  {
            if (items.filter((x: { [x: string]: any; }) => x['sprint_id'] === cv['sprint_id']).length <= 0) {
                items.push(cv as Sprint);
            }
            return items;
        }, []);
    const user_sprints: any[] = user_sprint_stats && user_sprint_stats.length > 0 ? user_sprint_stats[0]['sprints'] as any[] : [];
    const sprint_story_points = sprints.map(sp => {
        return {
            "sprint": sp,
            "story_points": story_points.reduce((n: any, s:number) => {
                const story_sprints = user_sprints.filter(it => it['sprint']['sprint_id'] === sp.sprint_id);
                const story_sprint = story_sprints && story_points.length > 0 ? story_sprints[0] : null;
                n[s] = story_sprint ? story_sprint['story_point_counts'][s] : 0;
                return n;
            }, {}) as { [story_point_value: string]: number }
        } as SprintStoryPointStats;
    });
    const users: UserSprintStatsForUserView[] = user_sprint_stats
        .flatMap(it => {
                return {
                    "user_id": it["user"]["user_id"],
                    "display_name": it["user"]["display_name"],
                    "sp_per_day": it["sp_per_day"],
                    "ratioed_by_top_performer": it["ratioed_by_top_performer"],
                    "last_sprint_sp_per_day": it["last_sprint_sp_per_day"],
                    "difficulty_r2": it["difficulty_r2"],
                    "sprints": (it['sprints'] as any[]).reduce((sp, o) => {
                        sp[o['sprint']['sprint_id']] = {
                            "sp_total": o["sp_total"],
                            "days_available": o["days_available"],
                            "story_point_count": o["story_point_counts"]
                        } as UserSprintWithR2
                        return sp;
                    }, {} as { [sprint_id: string]: UserSprintWithR2 })
                } as UserSprintStatsForUserView
            });
    const avg = 'avg_user_sprint_stat' in data ? data['avg_user_sprint_stat'] : null;
    const team = {
        "team_id": data["team"]["team_id"],
        "team_name": data["team"]["team_name"],
        "last_sprint_sp_per_day": avg ? avg["last_sprint_sp_per_day"] : 0,
        "sp_per_day": avg ? avg["sp_per_day"] : 0,
        "sprints": avg ? (avg['sprints'] as any[]).reduce((sp, o) => {
            sp[o['sprint']['sprint_id']] = {
                "sp_total": o["sp_total"],
                "days_available": o["days_available"]
            } as UserSprint
            return sp;
        }, {} as { [sprint_id: string]: UserSprint }) : {}
    }
    return { "sprints": sprint_story_points, "users": users, team: team, "story_points": story_points } as UserSprintStatsViewResponse;
}

export function getTeamVelocity(data: any): TeamVelocity[] {
    const sprints: any[] = 'sprints' in data ? data['sprints'] : [];
    if (!sprints || sprints.length <= 0) return [];
    const tv: TeamVelocity[] = sprints.map(sp => 
        {
            return {
            'sprint_id': sp['sprint']['sprint_id'],
            'sprint_name': sp['sprint']['sprint_name'],
            'start_date': sp['sprint']['start_date'],
            'end_date': sp['sprint']['finished_date'],
            'sp_total': sp['sp_total'],
            'sp_committed': sp['sp_committed'],
            'num_of_dev': sp['num_of_dev'],
            'dev_num_of_days_available': sp['dev_num_of_days_available'],
            'dev_num_of_days_off': sp['dev_num_of_days_off'],
            'num_of_qa': sp['num_of_qa'],
            'qa_num_of_days_available': sp['qa_num_of_days_available'],
            'qa_num_of_days_off': sp['qa_num_of_days_off'],
            'dev_num_of_hours_per_day': sp['dev_num_of_hours_per_day'],
            'qa_num_of_hours_per_day': sp['qa_num_of_hours_per_day'],
            'dev_hours_available': sp['dev_hours_available'],
            'qa_hours_available': sp['qa_hours_available'],
            'total_hours_available': sp['total_hours_available'],
            'previous_sprint_total_hours_available': sp['previous_sprint_total_hours_available'],
            'completed_rate_of_committed_sps': sp['completed_rate_of_committed_sps'],
            'hours_rate_of_last_sprint': sp['hours_rate_of_last_sprint'],
            'estimated_sps': sp['estimated_sps'],
            } as TeamVelocity;
        });
    return tv;
}