import * as React from 'react';

import { useAppSelector } from '../redux/hooks';
import { useGetTeamVelocityQuery, useSetTeamSprintCommittedSpMutation } from '../redux/slices/teamApiSlice';
import { Box, IconButton, Modal, Stack, Table, TableBody, TableCell, TableRow, TextField, Tooltip, Typography } from '@mui/material';
import { TeamVelocity } from '../models/stats';
import moment from 'moment-timezone';
import { open } from '../redux/slices/snackbarSlice';
import { FaPencil } from 'react-icons/fa6';

import './team_velocity.css';
import { useAppDispatch } from '../redux/hooks';
import { connect } from 'react-redux';
import { updateListItem } from '../utils/helpers';
import { SprintCommittedSP } from '../models/basic';
import SprintCommittedSPForm from '../components/SprintCommittedSPForm';
import { setSprintInfoChanged } from '../redux/slices/appStateSlice';

interface ITeamVelocityProps {
  teamId: number;
}

const loadProps = (state: any) =>
{
  return {
    teamId: state.appState.currentTeam?.team_id,
  }
}

const TeamVelocityView = (props: ITeamVelocityProps) => {
  const dispatch = useAppDispatch();
  const settingsChanged = useAppSelector((state) => state.appState.settingsChanged);  
  const dataRefreshed = useAppSelector((state) => state.appState.dataRefreshed);
  const { data: nodes, error, isLoading, refetch } = useGetTeamVelocityQuery(props.teamId, { refetchOnMountOrArgChange: true });
  const [data, setData] = React.useState([] as TeamVelocity[]);
  const [openSprintCommittedSPFormModal, setOpenSprintCommittedSPFormModal] = React.useState(false);
  const [sprintCommittedSP, setSprintCommittedSP] = React.useState<SprintCommittedSP | undefined>(undefined);

  React.useEffect(() => {
    if (settingsChanged || dataRefreshed) {
      refetch();
    }
    if (!nodes) return;
    setData(nodes || []);
  }, [nodes, settingsChanged, dataRefreshed]);
 
  function onSprintCommittedSPClick(row: TeamVelocity) {
    setSprintCommittedSP({
      team_id: props.teamId,
      sprint_id: row.sprint_id,
      sprint_name: row.sprint_name,
      sp_committed: row.sp_committed.toString()
    });
    setOpenSprintCommittedSPFormModal(!openSprintCommittedSPFormModal);
  }

  function handleSprintCommittedSPSave(sc: SprintCommittedSP) {
    if (data == null) return;
    updateListItem<TeamVelocity>(setData, o => o.sprint_id === sc.sprint_id, it =>
      {
        return { sp_committed: sc.sp_committed };
      });
    dispatch(setSprintInfoChanged(sc));
    setOpenSprintCommittedSPFormModal(false);
  }

  function handleSprintCommittedSPClose() {
    setOpenSprintCommittedSPFormModal(false);
  }

  return (
    <Stack spacing={2}>
      {isLoading && <div>Loading...</div>}
      {nodes && (
        <>
          { data.map((row, rowIdx) =>
            { return (
              <Table size="small" className='table' key={row.sprint_id}>
                <TableBody>
                  <TableRow key={row.sprint_id + '_1'}>
                    <TableCell align="center" rowSpan={4}>
                      {row.sprint_name}
                    </TableCell>
                    <TableCell align="center" rowSpan={2}>
                      Dates
                    </TableCell>
                    <TableCell align="center" rowSpan={2}>
                      People
                    </TableCell>
                    <TableCell align="center" rowSpan={2}>
                      Hours
                    </TableCell>
                    <TableCell align="center" rowSpan={2}>
                      Day
                    </TableCell>
                    <TableCell align="center" rowSpan={2}>
                      Day Off
                    </TableCell>
                    <TableCell align="center" rowSpan={2}>
                      Availability
                    </TableCell>
                    <TableCell align="center" rowSpan={2}>
                      Total
                    </TableCell>
                    <TableCell align="center" colSpan={5}>
                      Story Points
                    </TableCell>
                  </TableRow>
                  <TableRow key={row.sprint_id + '_2'}>
                    <TableCell align="center">
                        Estimate %
                      </TableCell>
                      <TableCell align="center">
                        Actual
                      </TableCell>
                      <TableCell align="center">
                        Committed
                      </TableCell>
                      <TableCell align="center">
                        Actual Done %
                      </TableCell>
                      <TableCell align="center">
                        Estimation
                      </TableCell>
                  </TableRow>
                  <TableRow key={row.sprint_id + '_3'}>
                    <TableCell align="center">
                      { moment(row.start_date).format('yyyy-MM-DD') }
                    </TableCell>
                    <TableCell align="center">
                      {row.num_of_dev}
                    </TableCell>
                    <TableCell align="center">
                      {row.dev_num_of_hours_per_day}
                    </TableCell>
                    <TableCell align="center">
                      {row.dev_num_of_days_available}
                    </TableCell>
                    <TableCell align="center">
                      {row.dev_num_of_days_off}
                    </TableCell>
                    <TableCell align="center">
                      {row.dev_hours_available}
                    </TableCell>
                    <TableCell align="center" rowSpan={2}>
                      {row.total_hours_available}
                    </TableCell>
                    <TableCell align="center" rowSpan={2}>
                      {(row.hours_rate_of_last_sprint * 100).toFixed(2)} %
                    </TableCell>
                    <TableCell align="center" rowSpan={2}>
                      {row.sp_total}
                    </TableCell>
                    <TableCell align="center" rowSpan={2} className='has-editor'>
                      {row.sp_committed}
                      {rowIdx == 0 &&
                        <Tooltip title="Set committed story points">
                          <IconButton color="primary" size='small' onClick={() => onSprintCommittedSPClick(row)}>
                              <FaPencil />
                          </IconButton>
                        </Tooltip>
                      }
                    </TableCell>
                    <TableCell align="center" rowSpan={2}>
                      {(row.completed_rate_of_committed_sps * 100).toFixed(2)} %
                    </TableCell>
                    <TableCell align="center" rowSpan={2}>
                      {row.estimated_sps}
                    </TableCell>
                  </TableRow>              
                  <TableRow key={row.sprint_id + '_4'}>
                    <TableCell align="center">
                      {moment(row.end_date).format('yyyy-MM-DD')}
                    </TableCell>
                    <TableCell align="center">
                      {row.num_of_qa}
                    </TableCell>
                    <TableCell align="center">
                      {row.qa_num_of_hours_per_day}
                    </TableCell>
                    <TableCell align="center">
                      {row.qa_num_of_days_available}
                    </TableCell>
                    <TableCell align="center">
                      {row.qa_num_of_days_off}
                    </TableCell>
                    <TableCell align="center">
                      {row.qa_hours_available}
                    </TableCell>
                  </TableRow>
              </TableBody>
              </Table>
            );
          })}
          <Modal open={openSprintCommittedSPFormModal} onClose={handleSprintCommittedSPClose} key="sprintCommittedSP">
            <Box className="model-box">
              <Stack>
                <Typography>Sprint Comment for {sprintCommittedSP?.sprint_name}</Typography>
                <SprintCommittedSPForm sprint={sprintCommittedSP} onSave={handleSprintCommittedSPSave} onClose={handleSprintCommittedSPClose} />
              </Stack>
            </Box>
          </Modal> 
        </>
      )}
    </Stack>
  );
};

export default connect(loadProps)(TeamVelocityView);