import { getSettingItems } from '../../datamapper/settings';
import { SettingItem } from '../../models/settings';
import { cmtApi } from './apiSlice';

export const settingsApiSlice = cmtApi.injectEndpoints({
    endpoints: builder => ({
        getSettings: builder.query<SettingItem[], void>({
            query: () => ({
                url: 'settings',
                method: 'GET',
            }),
            transformResponse: (response) => getSettingItems(response), 
            providesTags: ["Settings"]
        }),
        setSetting: builder.mutation<void, SettingItem[]>({
            query: (data) => ({
                url: '/settings',
                method: 'POST',
                body: data,
            }),
            invalidatesTags: ["Settings"]
        }),
        getTeamSettings: builder.query<SettingItem[], number>({
            query: (team_id) => ({
                url: `settings/team/${team_id}`,
                method: 'GET',
            }),
            transformResponse: (response) => getSettingItems(response), 
            providesTags: ["Settings"]
        }),
        setTeamSettings: builder.mutation<void, any>({
            query: ({team_id, data}) => ({
                url: `/settings/team/${team_id}`,
                method: 'POST',
                body: data,
            }),
            invalidatesTags: ["Settings"]
        }),
    })
})



export const { useGetSettingsQuery, useSetSettingMutation, useGetTeamSettingsQuery, useSetTeamSettingsMutation } = settingsApiSlice;