import React from 'react';
import './timeline.css';
import { useGetLoggedInUsersLeavesQuery } from '../redux/slices/leaveApiSlice';
import Timeline, { TimelineHeaders, DateHeader, TimelineGroupBase, TimelineItemBase } from 'react-calendar-timeline';
import moment, { Moment } from 'moment-timezone';
import {
  FormGroup,
  FormControlLabel,
  IconButton,
  Switch,
  Button,
  CircularProgress,
  Alert,
  AlertTitle,
  Popover,
  Typography
} from '@mui/material';
import { Stack } from '@mui/system';
import { IoArrowBackSharp, IoArrowForwardSharp } from 'react-icons/io5';
import { MdOutlineQuestionMark } from 'react-icons/md';
import { useGetProfileQuery } from '../redux/slices/userApiSlice';
import { User } from '../models/basic';
import { Leave } from '../models/leave';

const PersonalLeaveView = () => {
  const { data: user } = useGetProfileQuery();
  const { data: leaves, isFetching } = useGetLoggedInUsersLeavesQuery();
  const [currentUser, setCurrentUser] = React.useState<User | null>(null);
  const [groups, setGroups] = React.useState<TimelineGroupBase[]>([]);
  const [items, setItems] = React.useState<TimelineItemBase<Moment>[]>([]);
  const [visibleTimeStart, setVisibleTimeStart] = React.useState(moment().startOf('week').valueOf());
  const [visibleTimeEnd, setVisibleTimeEnd] = React.useState(moment().endOf('week').valueOf());
  const [isIgnoreTimezone, setIsIgnoreTimezone] = React.useState(false);
  const [legendAnchorEl, setLegendAnchorEl] = React.useState<null | HTMLElement>(null);

  React.useEffect(() => {
    if (!leaves || !user) return;

    const groups: TimelineGroupBase[] = [];
    const items: TimelineItemBase<Moment>[] = [];

    setCurrentUser(user);
    groups.push({ id: user.id, title: user.display_name });

    leaves.forEach((leave: Leave) => {
      items.push({
        id: leave.id,
        group: user.id,
        title: leave.summary,
        start_time: isIgnoreTimezone ? moment(leave.start_date, 'YYYY-MM-DD') : moment(leave.start_date),
        end_time: isIgnoreTimezone ? moment(leave.end_date, 'YYYY-MM-DD') : moment(leave.end_date),
        itemProps: {
          style: {
            background:
              leave.type === 'ANNUAL_AND_SICK' ? '#1d4ed8' : leave.type === 'PUBLIC_HOLIDAY' ? '#15803d' : '#f97316'
          }
        }
      });
    });
    setItems(items);
    setGroups(groups);
  }, [leaves, user, isIgnoreTimezone]);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setLegendAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setLegendAnchorEl(null);
  };

  const legendOpen = Boolean(legendAnchorEl);

  const toggleTimezone = () => {
    setIsIgnoreTimezone(!isIgnoreTimezone);
  };

  const handlePrevClick = () => {
    const zoom = visibleTimeEnd - visibleTimeStart;
    setVisibleTimeEnd(visibleTimeEnd - zoom);
    setVisibleTimeStart(visibleTimeStart - zoom);
  };

  const handleNextClick = () => {
    const zoom = visibleTimeEnd - visibleTimeStart;
    setVisibleTimeEnd(visibleTimeEnd + zoom);
    setVisibleTimeStart(visibleTimeStart + zoom);
  };

  return (
    <>
      {!currentUser || !items || !groups || isFetching ? (
        <div className="flex items-center justify-center p-5">
          <CircularProgress size="10rem" />
        </div>
      ) : (
        <div>
          {/* Show the users with no countries set using a mui alert component */}
          {!currentUser.country_timezone.country_name && (
            <Alert severity="warning">
              <AlertTitle>The following users do not have a country set:</AlertTitle>
              <div>{currentUser.display_name}</div>
            </Alert>
          )}
          <Stack direction="row" sx={{ m: 1 }} justifyContent="space-between" alignItems="center">
            <Stack direction="row" spacing={1} justifyContent="space-evenly" alignItems="center">
              <Button variant="outlined" onClick={handlePrevClick}>
                <IoArrowBackSharp />
              </Button>
              <Button variant="outlined" onClick={handleNextClick}>
                <IoArrowForwardSharp />
              </Button>
            </Stack>
            <Stack direction="row" spacing={1} justifyContent="space-evenly" alignItems="center">
              <FormGroup>
                <FormControlLabel
                  control={<Switch onClick={toggleTimezone} />}
                  label="Ignore Timezones"
                  sx={{ '& .MuiFormControlLabel-label': { fontSize: '0.9rem' } }}
                />
              </FormGroup>
              <Popover
                id="mouse-over-popover"
                sx={{
                  pointerEvents: 'none'
                }}
                open={legendOpen}
                anchorEl={legendAnchorEl}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'center'
                }}
                transformOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center'
                }}
                onClose={handlePopoverClose}
                disableRestoreFocus
              >
                <Stack sx={{ p: 2 }} spacing={2}>
                  <div>
                    <dt className="bg-blue-700 inline-block w-5 h-5 align-middle"></dt>
                    <Typography className="inline-block px-5 align-middle" variant="body2">
                      Annual and Sick Leave
                    </Typography>
                  </div>
                  <div>
                    <dt className="bg-green-700 inline-block w-5 h-5 align-middle"></dt>
                    <Typography className="inline-block px-5 align-middle" variant="body2">
                      Public Holiday
                    </Typography>
                  </div>
                  <div>
                    <dt className=" bg-orange-500 inline-block w-5 h-5 align-middle"></dt>
                    <Typography className="inline-block px-5 align-middle" variant="body2">
                      Other Leave
                    </Typography>
                  </div>
                </Stack>
              </Popover>
              <IconButton color="primary" onMouseEnter={handlePopoverOpen} onMouseLeave={handlePopoverClose}>
                <MdOutlineQuestionMark />
              </IconButton>
            </Stack>
          </Stack>
          <div className="w-[100%] rounded-md border-2 border-spacing-1 border-neutral-300 overflow-hidden">
            <Timeline
              groups={groups}
              items={items}
              buffer={1}
              visibleTimeStart={visibleTimeStart}
              visibleTimeEnd={visibleTimeEnd}
              canMove={false}
              canResize={false}
            >
              <TimelineHeaders>
                <DateHeader unit="week" labelFormat="MMMM YYYY" className="bg-navman_green text-white" />
                <DateHeader unit="day" labelFormat="ddd, MMM Do" className="bg-gray-200" />
              </TimelineHeaders>
            </Timeline>
          </div>
        </div>
      )}
    </>
  );
};

export default PersonalLeaveView;
