import React, { useEffect } from "react";
import { Stack, Typography } from "@mui/material";

interface ITeamSprintRowHeaderProps {
    title:string,
    isClickable:boolean,
    onClick?: (e:React.MouseEvent<HTMLElement>)=>void
    highlightedRowKey:string
    setHighlightedRowKey:React.Dispatch<React.SetStateAction<string>>
    rowKey:string
}

function TeamSprintRowHeader(props:ITeamSprintRowHeaderProps) {
    return (
    <div className={` flex justify-center items-center h-9 bg-gray-50 rounded-md shadow-inner shadow-gray-200 px-6 my-1 transition-all
    ${props.isClickable ? 'shadow-sm hover:shadow-sm hover:shadow-gray-400 hover:cursor-pointer active:shadow-inner': ''} 
    ${props.highlightedRowKey == props.rowKey ? 'bg-navman_green_grey_highlight shadow-none' : 'bg-gray-50 shadow-inner'}`}
    onMouseEnter={()=>{props.setHighlightedRowKey(props.rowKey)}}
    onMouseLeave={()=>{props.setHighlightedRowKey('')}}
    onClick={props.onClick ? props.onClick : ()=>{}}>
        <Typography variant={`${props.isClickable? 'subtitle2' :'caption'}`}>{props.title}</Typography>
    </div>)
}

export default TeamSprintRowHeader;