import Timeline, {
  TimelineHeaders,
  DateHeader,
  TimelineGroupBase,
  TimelineItemBase,
  Id
} from 'react-calendar-timeline';
import './timeline.css';
import moment, { Moment } from 'moment-timezone';
import { useGetTeamLeavesQuery } from '../redux/slices/teamApiSlice';
import React from 'react';
import {
  AlertTitle,
  Box,
  Button,
  CircularProgress,
  FormControlLabel,
  FormGroup,
  IconButton,
  Modal,
  Popover,
  Stack,
  Switch,
  Tooltip,
  Typography
} from '@mui/material';
import { IoArrowForwardSharp, IoArrowBackSharp } from 'react-icons/io5';
import Alert from '@mui/material/Alert';
import { MdRefresh } from 'react-icons/md';
import LeaveForm from './LeaveForm';
import { MdOutlineEditCalendar } from 'react-icons/md';
import ConfirmModal from '../controls/ConfirmModal';
import { useDeleteLeaveMutation } from '../redux/slices/leaveApiSlice';
import { useRefreshLeaveMutation } from '../redux/slices/leaveApiSlice';
import { useAppDispatch, useAppSelector } from '../redux/hooks';
import { open } from '../redux/slices/snackbarSlice';
import { MdOutlineQuestionMark } from 'react-icons/md';
import { PermissionLevel } from '../models/permissionLevels';
import { useGetGlobalPermissionQuery } from '../redux/slices/authApiSlice';
import { hasExactPermission } from '../utils/auth';
import { Sprint, User } from '../models/basic';
import { UserLeaves } from '../models/leave';
import { connect } from 'react-redux';

const sprint_days = 14;

interface ISchedulerProps {
  teamId: number;
  startDate: Date;
  endDate: Date;
  sprint: Sprint | null;
  sprints: Sprint[];
}

const loadProps = (state: any) =>
{
  return {
    teamId: state.appState.currentTeam?.team_id,
    sprint: state.appState.currentSprint,
    startDate: state.appState.currentSprint?.start_date ?? moment().startOf('week').toDate(),
    endDate: (state.appState.currentSprint?.finished_date || state.appState.currentSprint?.end_date) ?? moment().endOf('week').add(sprint_days, 'day').toDate(), // by default, a sprint span is 2 weeks.
    sprints: state.appState.sprints,
  }
}

const SchedulerComponent = (props: ISchedulerProps) => {
  const { teamId } = props;
  const dispatch = useAppDispatch();
  const { data: leaves } = useGetTeamLeavesQuery(teamId);
  const [isIgnoreTimezone, setIsIgnoreTimezone] = React.useState<boolean>(false);
  const [users, setUsers] = React.useState<User[]>([]);
  const [userGroups, setUserGroups] = React.useState<TimelineGroupBase[]>([]);
  const [items, setItems] = React.useState<TimelineItemBase<Moment>[]>([]);
  const [visibleTimeStart, setVisibleTimeStart] = React.useState(moment(props.startDate));
  const [visibleTimeEnd, setVisibleTimeEnd] = React.useState(moment(props.endDate));
  const [localSprint, setLocalSprint] = React.useState(props.sprint);
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [countrylessUsers, setCountrylessUsers] = React.useState<UserLeaves[]>([]); // Users without a country
  const [openFormModal, setOpenFormModal] = React.useState(false);
  const [deleteConfirmOpen, setDeleteConfirmOpen] = React.useState(false);
  const [selectedLeaveId, setSelectedLeaveId] = React.useState<Id | null>(null);
  const [deleteLeave] = useDeleteLeaveMutation();
  const [refreshLeave, { isLoading: isLeaveRefreshing }] = useRefreshLeaveMutation();
  const [legendAnchorEl, setLegendAnchorEl] = React.useState<null | HTMLElement>(null);

  React.useEffect(() => {
    setLocalSprint(props.sprint);
    setVisibleTimeStart(moment(props.startDate));
    setVisibleTimeEnd(moment(props.endDate));

    if (!leaves) return; // Exit early if leaves are falsy

    setIsLoading(true);
    const users: User[] = [];
    const userGroups: TimelineGroupBase[] = [];
    const countrylessUsers: UserLeaves[] = [];
    const items: TimelineItemBase<Moment>[] = [];

    leaves.forEach((userGroup) => {
      if (!userGroup.country_timezone.country_name) {
        countrylessUsers.push(userGroup);
      }
      userGroups.push({
        id: userGroup.user_id,
        title: userGroup.display_name
      });
      users.push({
        id: userGroup.user_id,
        email: userGroup.email,
        display_name: userGroup.display_name,
        country_timezone: userGroup.country_timezone,
        permission_level: userGroup.permission_level
      });
    });

    leaves?.forEach((user) => {
      user.leaves.forEach((leave) => {
        items.push({
          id: leave.id,
          group: user.user_id,
          title: leave.summary,
          start_time: isIgnoreTimezone ? moment(leave.start_date, 'YYYY-MM-DD') : moment(leave.start_date),
          end_time: isIgnoreTimezone ? moment(leave.end_date, 'YYYY-MM-DD') : moment(leave.end_date),
          className: leave.type.toLowerCase(),
        });
      });
    });

    setUsers(users);
    setUserGroups(userGroups);
    setCountrylessUsers(countrylessUsers);
    setItems(items);
    setIsLoading(false);
  }, [props, leaves, isIgnoreTimezone]);

  const { data: userPermission } = useGetGlobalPermissionQuery();

  function hasPermissionOnly(permissionLevel: PermissionLevel) {
    return hasExactPermission(permissionLevel, userPermission);
  }

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setLegendAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setLegendAnchorEl(null);
  };

  const legendOpen = Boolean(legendAnchorEl);

  const toggleTimezone = () => {
    setIsIgnoreTimezone(!isIgnoreTimezone);
  };

  const handlePrevClick = () => {
    var prevTimeEnd = visibleTimeStart.clone().subtract(Math.floor(sprint_days/2), 'days');
    var idx = props.sprints.findIndex(o => moment(o.start_date) < prevTimeEnd && moment(o.finished_date || o.end_date) > prevTimeEnd)
    if (idx >= 0 && idx <= props.sprints.length - 1) // found sprint
    {
      // the sprints is a reversed list
        var prev = props.sprints[idx];
        setLocalSprint(prev);
        setVisibleTimeStart(moment(prev.start_date));
        setVisibleTimeEnd(moment(prev.finished_date || prev.end_date));
        return;
    }
    setLocalSprint(null);
    var currTimeStart = visibleTimeStart.clone();
    setVisibleTimeEnd(visibleTimeStart);
    setVisibleTimeStart(currTimeStart.subtract(sprint_days, 'days'));
  };

  const handleNextClick = () => {
    var nextTimeEnd = visibleTimeEnd.clone().add(Math.floor(sprint_days/2), 'days');
    var idx = props.sprints.findIndex(o => moment(o.start_date) < nextTimeEnd && moment(o.finished_date || o.end_date) > nextTimeEnd)
    if (idx >= 0 && idx <= props.sprints.length - 1) // found sprint
    {
        var next = props.sprints[idx];
        setLocalSprint(next);
        setVisibleTimeStart(moment(next.start_date));
        setVisibleTimeEnd(moment(next.finished_date || next.end_date));
        return;
    }
    setLocalSprint(null);
    var currTimeEnd = visibleTimeEnd.clone();
    setVisibleTimeStart(visibleTimeEnd);
    setVisibleTimeEnd(currTimeEnd.add(sprint_days, 'days'));
  };

  const handleClose = () => {
    setOpenFormModal(false);
  };

  const handleOpen = () => {
    setOpenFormModal(true);
  };

  const handleSelect = (leaveId: Id) => {
    setDeleteConfirmOpen(true);
    setSelectedLeaveId(leaveId);
    setDeleteConfirmOpen(true);
  };

  const handleDeselect = () => {
    setDeleteConfirmOpen(false);
    setSelectedLeaveId(null);
  };

  const handleRefreshLeave = async () => {
    await refreshLeave().unwrap();
    dispatch(open({ open: true, message: 'Leave refreshed successfully', severity: 'info' }));
  };

  const handleDelete = async () => {
    setDeleteConfirmOpen(false);

    // Remove the leave from the database
    await deleteLeave(selectedLeaveId as number).unwrap();
    dispatch(open({ open: true, message: 'Leave deleted successfully', severity: 'info' }));
    setSelectedLeaveId(null);
  };

  const handleCancelDelete = () => {
    setDeleteConfirmOpen(false);
    setSelectedLeaveId(null);
  };

  return (
    <>
      {/* Show the users with no countries set using a mui alert component */}
      {countrylessUsers.length > 0 && (
        <Alert severity="warning">
          <AlertTitle>The following users do not have a country set:</AlertTitle>
          {countrylessUsers.map((user) => (
            <div key={user.user_id}>{user.display_name}</div>
          ))}
        </Alert>
      )}
      {isLoading || isLeaveRefreshing ? (
        <div className="flex items-center justify-center p-5">
          <CircularProgress size="10rem" />
        </div>
      ) : (
        <div>
          <Stack direction="row" sx={{ m: 1 }} justifyContent="space-between" alignItems="center">
            <Stack direction="row" spacing={1} justifyContent="space-evenly" alignItems="center">
              <Button variant="outlined" onClick={handlePrevClick}>
                <IoArrowBackSharp />
              </Button>
              <Button variant="outlined" onClick={handleNextClick}>
                <IoArrowForwardSharp />
              </Button>
            </Stack>
            <Stack direction="row">
              <Typography variant='h6'>{localSprint?.sprint_name}</Typography>
              <Typography variant='caption' sx={{fontSize:'1rem', margin: '4px 0 0 16px'}}>{visibleTimeStart.format('YYYY-MM-DD')} to {visibleTimeEnd.format('YYYY-MM-DD')}</Typography>
            </Stack>
            <Stack direction="row" spacing={1} justifyContent="space-evenly" alignItems="center">
              <FormGroup>                
                <FormControlLabel
                  control={<Switch onClick={toggleTimezone} />}
                  label="Ignore Timezones"
                  sx={{ '& .MuiFormControlLabel-label': { fontSize: '0.9rem' } }}
                />
              </FormGroup>
              <Popover
                id="mouse-over-popover"
                sx={{
                  pointerEvents: 'none'
                }}
                open={legendOpen}
                anchorEl={legendAnchorEl}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'center'
                }}
                transformOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center'
                }}
                onClose={handlePopoverClose}
                disableRestoreFocus
              >
                <Stack sx={{ p: 2 }} spacing={2}>
                  <div>
                    <dt className="bg-blue-700 inline-block w-5 h-5 align-middle"></dt>
                    <Typography className="inline-block px-5 align-middle" variant="body2">
                      Annual and Sick Leave
                    </Typography>
                  </div>
                  <div>
                    <dt className="bg-green-700 inline-block w-5 h-5 align-middle"></dt>
                    <Typography className="inline-block px-5 align-middle" variant="body2">
                      Public Holiday
                    </Typography>
                  </div>
                  <div>
                    <dt className=" bg-orange-500 inline-block w-5 h-5 align-middle"></dt>
                    <Typography className="inline-block px-5 align-middle" variant="body2">
                      Other Leave
                    </Typography>
                  </div>
                </Stack>
              </Popover>
              <IconButton color="primary" onMouseEnter={handlePopoverOpen} onMouseLeave={handlePopoverClose}>
                <MdOutlineQuestionMark />
              </IconButton>
              <IconButton color="primary" onClick={handleOpen} disabled={hasPermissionOnly(PermissionLevel.ALL_TEAMS)}>
                <MdOutlineEditCalendar />
              </IconButton>
              <Tooltip title="Refreshes site-wide leave" placement="top">
                <span>
                  <IconButton
                    color="primary"
                    onClick={handleRefreshLeave}
                    disabled={hasPermissionOnly(PermissionLevel.ALL_TEAMS)}
                  >
                    <MdRefresh />
                  </IconButton>
                </span>
              </Tooltip>
            </Stack>
          </Stack>
          <div className="w-[100%] rounded-md border-2 border-spacing-1 border-neutral-300 overflow-hidden">
            <Timeline
              groups={userGroups}
              items={items}
              buffer={1}
              minZoom={14 * 60 * 60 * 1000}
              maxZoom={14 * 60 * 60 * 1000}
              visibleTimeStart={visibleTimeStart.clone().add(-1, 'day').valueOf()}
              visibleTimeEnd={visibleTimeEnd.clone().add(1, 'day').valueOf()}
              sidebarWidth={150}
              canMove={false}
              canResize={false}
              selected={selectedLeaveId ? [selectedLeaveId] : []}
              onItemSelect={(itemId) => {
                handleSelect(itemId);
              }}
              onItemDeselect={() => {
                handleDeselect();
              }}
            >
              <TimelineHeaders>
                <DateHeader unit="week" labelFormat="MMMM YYYY [(Week ]w[)]" className="bg-navman_green text-white" />
                <DateHeader unit="day" labelFormat="ddd, DD MMM" className="bg-gray-200" />
              </TimelineHeaders>
            </Timeline>
          </div>
        </div>
      )}
      <Modal open={openFormModal} onClose={handleClose}>
        <Box className="model-box">
          <LeaveForm userOptions={users} onClose={handleClose} />
        </Box>
      </Modal>
      <ConfirmModal
        title={'Are you sure you wish to delete this leave?'}
        message={'This action cannot be undone.'}
        onConfirm={handleDelete}
        onClose={handleCancelDelete}
        isOpen={deleteConfirmOpen}
        setIsOpen={setDeleteConfirmOpen}
      ></ConfirmModal>
    </>
  );
};

export default connect(loadProps)(SchedulerComponent);

