import * as React from 'react';
import {
  Button,
  FormControl,
  Grid,
  Stack,
  TextField,
  Typography
} from '@mui/material';
import { useAppDispatch } from '../redux/hooks';
import { open } from '../redux/slices/snackbarSlice';
import { useSetTeamSprintCommentMutation } from '../redux/slices/teamApiSlice';
import { TeamSprintComment } from '../models/basic';

interface TeamSprintCommentFormProps {
  sprintComment: TeamSprintComment
  onSave: (sc: TeamSprintComment) => void;
  onClose: () => void;
}

const TeamSprintCommentForm = (props: TeamSprintCommentFormProps) => {
  const dispatch = useAppDispatch();
  const [setTeamSprintInfo] = useSetTeamSprintCommentMutation();
  const [data, setData] = React.useState(props.sprintComment);

  async function handleSubmit() {
    await setTeamSprintInfo(data).unwrap();
    props.onSave(data);
    // Show success snackbar and navigate to the team page
    dispatch(
      open({
        open: true,
        message: 'Sprint comment saved successfully',
        severity: 'success'
      })
    );
  }

  function setTeamSprintComment(val: string) {
    setData({ ...data, comment: val });
    return true;
  }

  return (
    <Stack spacing={2}>
      {data && (
        <>
          <Grid container spacing={2} sx={{ width: '100%' }}>
              <Grid item xs={12} key="sprint_comment">
                <FormControl fullWidth>
                  <label>Comment</label>
                  <TextField
                    variant="outlined"
                    type={'multiline'}
                    value={(data.comment || '')}
                    onChange={(e) => setTeamSprintComment(e.target.value)}
                  />
                </FormControl>
              </Grid>
          </Grid>
          <Stack direction={'row'}>
            <div style={{ flexGrow: '1' }}></div>
            <Button variant="contained" sx={{ color: 'white', margin: '0 8px' }} onClick={handleSubmit}>
              Save
            </Button>
            <Button variant="contained" color="secondary" sx={{ color: 'white', margin: '0 8px' }} onClick={props.onClose}>
              Close
            </Button>
          </Stack>
        </>
      )}
    </Stack>
  );
};


export default TeamSprintCommentForm;