import React from 'react';
import { MdGroupAdd } from 'react-icons/md';
import { FaUserGear, FaRightFromBracket, FaGear } from 'react-icons/fa6';
import { useNavigate } from 'react-router-dom';
import { GiIsland } from 'react-icons/gi';
import { useGetGlobalPermissionQuery } from '../redux/slices/authApiSlice';
import { PermissionLevel } from '../models/permissionLevels';
import { hasPermission } from '../utils/auth';
import { IoHome } from 'react-icons/io5';

/**
 * This component is the sidebar that is displayed on the left side of the screen.
 * @returns the SideBar component.
 */
const SideBar = () => {
  const { data: userPermission } = useGetGlobalPermissionQuery();

  return (
    <div className="fixed top-0 left-0 h-screen w-16 m-0 flex flex-col shadow-md shadow-slate-700 bg-primary text-secondary z-50 border-r-2 border-r-navman_green">
      <SideBarIcon icon={<IoHome size="28" />} text="Home" link="/home" visible={hasPermission(PermissionLevel.USER, userPermission)} />
      <SideBarIcon icon={<MdGroupAdd size="28" />} text="Add a Team" link="/teams" visible={hasPermission(PermissionLevel.MANAGER, userPermission)} />
      <SideBarIcon icon={<FaUserGear size="28" />} text="Manage Users" link="/users" visible={hasPermission(PermissionLevel.MANAGER, userPermission)}  />
      <SideBarIcon icon={<GiIsland size="28" />} text="Manage Public Holidays" link="/holidays" visible={hasPermission(PermissionLevel.ADMIN, userPermission)} />
      <SideBarIcon icon={<FaGear size="28" />} text="Settings" link="/settings" visible={hasPermission(PermissionLevel.ADMIN, userPermission)} />
      <SideBarIcon icon={<FaRightFromBracket size="28" />} text="Logout" link="/logout" visible={true} style={{marginTop: 'auto'}} />
    </div>
  );
};

interface SideBarIconProps {
  icon: React.ReactNode;
  text: string;
  link: string;
  visible: boolean;
  style?: any;
}

/**
 * This component is a single icon in the sidebar.
 * @param icon the icon to display.
 * @param text the text to display when the user hovers over the icon.
 * @param link the link to navigate to when the user clicks on the icon.
 * @returns the SideBarIcon component.
 */
const SideBarIcon = ({ icon, text, link, visible, style = undefined }: SideBarIconProps) => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(link);
  };
  if (visible) {
    return (
      <div className="sidebar-icon group" onClick={handleClick} style={style}>
        {icon}
        <span className="sidebar-tooltip group-hover:scale-100">{text}</span>
      </div>
    );
  }
  return (<></>)
};

export default SideBar;
