import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';

export interface IConfirmProps {
  title: string;
  message: string;
  onConfirm: () => void;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  onClose?: () => void;
}

function ConfirmModal(props: IConfirmProps) {
  function closeModal() {
    props.setIsOpen(false);
    props.onClose && props.onClose();
  }
  function acceptModal() {
    props.setIsOpen(false);
    props.onConfirm();
  }

  return (
    <Dialog open={props.isOpen} onClose={closeModal}>
      <DialogTitle id="confirm-modal-title">{props.title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="confirm-modal-description" className="py-2">
          {props.message}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" color={'error'} onClick={closeModal}>
          Cancel
        </Button>
        <Button variant="outlined" color={'success'} onClick={acceptModal} autoFocus>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ConfirmModal;
