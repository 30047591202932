import React from 'react';
import { CircularProgress, Divider, Popover, Stack, Typography, IconButton, Modal, Box, Tooltip, FormGroup, FormControlLabel, Switch } from '@mui/material';
import TeamSprintRowHeader from './TeamSprintRowHeader';
import TeamSprintUser from './TeamSprintUser';
import { useAppSelector } from '../redux/hooks';
import { useGetTeamStatsQuery } from '../redux/slices/teamApiSlice';
import { connect } from 'react-redux';
import { setCurrentSprint, setSprints } from '../redux/slices/appStateSlice';
import { useAppDispatch } from '../redux/hooks';
import { Sprint, TeamSprintComment } from '../models/basic';
import moment, { Moment } from 'moment';
import { FaCaretLeft, FaCaretRight, FaPencil, FaCopy } from 'react-icons/fa6';
import TeamSprintCommentForm from './TeamSprintCommentForm';
import { SprintStats } from '../models/stats';
import { updateListItem } from '../utils/helpers';

interface ITeamSprintsProps {
  teamId: number;
}

const loadProps = (state: any) =>
{
  return {
    teamId: state.appState.currentTeam?.team_id,
  }
}

function TeamSprintsView(props: ITeamSprintsProps) {
  interface SprintSummary {
    sprintName: string;
    startDate: Moment;
    endDate: Moment;
    SPTotal: number;
    DaysTotal: number;
    SPPerDay: number;
    jql: string;
  }

  const isManagerView = useAppSelector((state) => state.viewSelector.isManagerView);
  const settingsChanged = useAppSelector((state) => state.appState.settingsChanged);
  const dataRefreshed = useAppSelector((state) => state.appState.dataRefreshed);
  const sprintInfoChanged = useAppSelector((state) => state.appState.sprintInfoChanged);
  const [isPopoverOpen, setIsPopoverOpen] = React.useState(false);
  const [sprintSummary, setSprintSummary] = React.useState<SprintSummary | undefined>(undefined);
  const [teamSprintComment, setTeamSprintComment] = React.useState<TeamSprintComment | undefined>(undefined);
  const [anchorE1, setAnchorE1] = React.useState<HTMLElement | null>(null);
  const [highlightedRowKey, setHighlightedRowKey] = React.useState('')

  const [isUserStatCollapsed, setUserStatCollapsed] = React.useState(false);
  const [isTeamStatCollapsed, setTeamStatCollapsed] = React.useState(false);
  const [openTeamSprintCommentFormModal, setOpenTeamSprintCommentFormModal] = React.useState(false);
  const [showJql, setShowJql] = React.useState(false);

  const { data: teamStats, isError, isLoading, refetch } = useGetTeamStatsQuery(props.teamId);
  const [data, setData] = React.useState([] as SprintStats[]);
  const dispatch = useAppDispatch();
  
  React.useEffect(() => {
    if (settingsChanged || dataRefreshed || sprintInfoChanged !== null) {
      refetch();
    }
    if (teamStats && teamStats.sprints && teamStats.sprints.length > 0) {
      dispatch(setSprints(teamStats.sprints));
      dispatch(setCurrentSprint(teamStats.sprints[0]));
      setData(teamStats.sprint_stats || []);
    }
  }, [teamStats, settingsChanged, dataRefreshed, sprintInfoChanged]);

  function handleOpenSummary(sprint: Sprint) {
    dispatch(setCurrentSprint(sprint));
    setIsPopoverOpen(true);
    var SPTotal = 0;
    var daysTotal = 0;
    if (!teamStats) {
      return;
    }
    teamStats.users.forEach((userSprintStats) => {
      try {
        SPTotal += userSprintStats.sprints[sprint.sprint_id].sp_total;
        daysTotal += userSprintStats.sprints[sprint.sprint_id].days_available;
      } catch (err) {}
    });
    const sprintState = data.filter((sprintStat) => sprintStat.sprint.sprint_id == sprint.sprint_id)[0];
    setSprintSummary({
      sprintName: sprint.sprint_name,
      startDate: moment(sprint.start_date),
      endDate: moment(sprint.finished_date || sprint.end_date),
      SPTotal: SPTotal,
      DaysTotal: daysTotal,
      SPPerDay: SPTotal / daysTotal,
      jql: sprintState.jql
    });
  }  

  function onTeamSprintCommentClick(sprintStat: SprintStats) {
    setTeamSprintComment({
      team_id: props.teamId,
      sprint_id: sprintStat.sprint.sprint_id,
      sprint_name: sprintStat.sprint.sprint_name,
      comment: sprintStat.comments
    });
    setOpenTeamSprintCommentFormModal(!openTeamSprintCommentFormModal);
  }

  function handleTeamSprintCommentSave(sc: TeamSprintComment) {
    if (teamStats == null) return;
    updateListItem<SprintStats>(setData, o => o.sprint.sprint_id == sc.sprint_id, it =>
      {
        return { comments: sc.comment };
      });
    setOpenTeamSprintCommentFormModal(false);
  }

  function handleSprintCommentClose() {
    setOpenTeamSprintCommentFormModal(false);
  }

  function toggleShowJql() {
    setShowJql(!showJql);
  }

  function copy(text: string) {
    navigator.clipboard.writeText(text);
  }

  return (
    <>
      {isError ? (
        <div className="flex items-center justify-center p-5 py-10">
          <Typography variant="h6" color={'error'}>
            Error Loading Stats
          </Typography>
        </div>
      ) : !teamStats ? (
        <div className="flex items-center justify-center p-5">
          <CircularProgress size="10rem" />
        </div>
      ) : (
        <div className="flex">          
          <Stack direction={'column'} className=" min-w-fit overflow-hidden pr-3">
            <div className=" h-32 mt-4">
              {isManagerView && (
              <FormGroup>
                <FormControlLabel
                  control={<Switch size="medium" checked={showJql} onChange={toggleShowJql} />}
                  label="Show JQL"
                />
              </FormGroup>
              )}
            </div>
            {teamStats.sprints.map((sprint, index) => (
              <TeamSprintRowHeader
                key={index}
                title={sprint.sprint_name}
                isClickable={true}
                onClick={(event) => {
                  handleOpenSummary(sprint);
                  setAnchorE1(event.currentTarget);
                } } highlightedRowKey={highlightedRowKey} setHighlightedRowKey={setHighlightedRowKey} rowKey={sprint.sprint_id.toString()} />
            ))}
            {!sprintSummary ? null : (
              <Popover
                open={isPopoverOpen}
                onClose={() => {
                  setIsPopoverOpen(false);
                  setAnchorE1(null);
                }}
                anchorEl={anchorE1}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center'
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center'
                }}
              >
                <div className=" grid px-4 py-4 gap-y-1 grid-cols-2 items-center">
                  <Typography variant="subtitle2" className=" col-span-2 pr-2">
                    {sprintSummary.startDate.format('YYYY-MM-DD HH:mm')} to {sprintSummary.endDate.format('YYYY-MM-DD HH:mm')}
                  </Typography>
                  <Typography variant="subtitle2" className=" pr-2">
                    Sprint Total
                  </Typography>
                  <Typography
                    variant="caption"
                    className=" h-fit py-1 rounded-md shadow-inner bg-gray-50 shadow-slate-200 text-center"
                  >
                    {sprintSummary.SPTotal.toFixed(1)}
                  </Typography>

                  <Typography variant="subtitle2" className=" pr-2">
                    Days Total
                  </Typography>
                  <Typography
                    variant="caption"
                    className=" h-fit py-1 rounded-md shadow-inner bg-gray-50 shadow-slate-200 text-center"
                  >
                    {sprintSummary.DaysTotal.toFixed(1)}
                  </Typography>

                  <Typography variant="subtitle2" className=" pr-2">
                    SP/Day
                  </Typography>
                  <Typography
                    variant="caption"
                    className=" h-fit py-1 rounded-md shadow-inner bg-gray-50 shadow-slate-200 text-center"
                  >
                    {sprintSummary.SPPerDay.toFixed(1)}
                  </Typography>
                  { isManagerView && showJql && (
                  <Tooltip title={'copy jql for sprint ' + sprintSummary.sprintName}>
                    <IconButton color="primary" size='small' onClick={() => copy(sprintSummary.jql)} style={{ width: '1.5em'}}>
                        <FaCopy />
                    </IconButton>
                  </Tooltip>
                  )}
                </div>
              </Popover>
            )}

            <div className=" h-5"></div>
            <TeamSprintRowHeader title="SP per Day" isClickable={false} highlightedRowKey={highlightedRowKey} setHighlightedRowKey={setHighlightedRowKey} rowKey={'sp_per_day'} />
            <TeamSprintRowHeader title="Team Avg SP/Day" isClickable={false} highlightedRowKey={highlightedRowKey} setHighlightedRowKey={setHighlightedRowKey} rowKey={'team_avg_sp_per_day'} />
            <TeamSprintRowHeader title="% From Team Avg" isClickable={false} highlightedRowKey={highlightedRowKey} setHighlightedRowKey={setHighlightedRowKey} rowKey={'percent_diff_from_avg'} />
            <TeamSprintRowHeader title="% From Top Performer" isClickable={false} highlightedRowKey={highlightedRowKey} setHighlightedRowKey={setHighlightedRowKey} rowKey={'ratioed_by_top_performer'} />
            <TeamSprintRowHeader title="Last Sprint SP/Day" isClickable={false} highlightedRowKey={highlightedRowKey} setHighlightedRowKey={setHighlightedRowKey} rowKey={'last_sprint_sp_per_day'} />
            <TeamSprintRowHeader title="Last Sprint Improving on Team Avg" isClickable={false} highlightedRowKey={highlightedRowKey} setHighlightedRowKey={setHighlightedRowKey} rowKey={'last_sprint_improv_on_team_avg'} />
            <TeamSprintRowHeader title="% of Team SP" isClickable={false} highlightedRowKey={highlightedRowKey} setHighlightedRowKey={setHighlightedRowKey} rowKey={'percent_of_team_sp'} />
            <TeamSprintRowHeader title="R^2 per Day" isClickable={false} highlightedRowKey={highlightedRowKey} setHighlightedRowKey={setHighlightedRowKey} rowKey={'difficulty_r2'} />
          </Stack>
          <div className="team-spring-view-container">
          <div className={`col-group flex ${isUserStatCollapsed ? 'collapsed' : ''}`}>
            <div className=" flex-grow flex flex-row px-2 self-stretch shadow-inner shadow-slate-300 bg-gray-50 rounded-md overflow-x-auto border-2 border-gray-200">
              {teamStats.users.map((userSprintInfo, index) => (
                <TeamSprintUser key={index} sprints={teamStats.sprints} userSprintStats={userSprintInfo} highlightedRowKey={highlightedRowKey} setHighlightedRowKey={setHighlightedRowKey} showJql={isManagerView && showJql} />
              ))}
            </div>
          </div>
          <div className={`spliter ${isUserStatCollapsed ? 'collapsed' : ''}`} onClick={() => setUserStatCollapsed(!isUserStatCollapsed)}>
              <IconButton color="primary">
                { isUserStatCollapsed ? <FaCaretRight /> : <FaCaretLeft /> }
              </IconButton>
          </div>
          <div className={`col-group flex ${isTeamStatCollapsed ? 'collapsed' : ''}`}>
            <div className=" flex-grow flex flex-row px-2 self-stretch shadow-inner shadow-slate-300 bg-gray-50 rounded-md overflow-x-auto border-2 border-gray-200">
              <Stack direction={'column'} className=" w-fit py-2 mx-2 ">
                <div className="flex flex-col items-center justify-center h-30 px-3 bg-white rounded-md shadow-sm shadow-gray-300 border-navman_green border-b-2 mb-1">
                  <Typography variant="subtitle1" className="text-center">
                    {teamStats.team.team_name}
                  </Typography>
                  <div className="flex w-fit py-0.5 mt-1 mb-2 rounded-md shadow-inner bg-gray-50 shadow-slate-200">
                    <Typography variant="caption" className=" h-20 w-20 text-center content-center">
                      QA
                    </Typography>
                    <Divider orientation="vertical" flexItem />
                    <Typography variant="caption" className=" h-20 w-20 text-center content-center">
                      Sprint Total
                    </Typography>
                    <Divider orientation="vertical" flexItem />
                    <Typography variant="caption" className=" h-20 w-20 text-center content-center">
                      Days Total
                    </Typography>
                    <Divider orientation="vertical" flexItem />
                    <Typography variant="caption" className=" h-20 w-20 text-center content-center">
                      SP / day
                    </Typography>
                    <Divider orientation="vertical" flexItem />
                    <Typography variant="caption" className=" h-20 w-20 text-center content-center">
                      % Complete
                    </Typography>
                    <Divider orientation="vertical" flexItem />
                    <div className="flex flex-col w-fit mt-1">
                      <Typography variant="subtitle2" className=" text-center content-center py-1.5">
                        Support / BAU
                      </Typography>
                      <Divider orientation="horizontal" flexItem />
                      <div className="flex w-fit">
                        <Typography variant="caption" className=" h-10 w-20 text-center content-center">
                          #
                        </Typography>  
                        <Divider orientation="vertical" flexItem />
                        <Typography variant="caption" className=" h-10 w-20 text-center content-center">
                          SPs
                        </Typography>  
                        <Divider orientation="vertical" flexItem />
                        <Typography variant="caption" className=" h-10 w-20 text-center content-center">
                          days
                        </Typography>   
                        <Divider orientation="vertical" flexItem />
                        <Typography variant="caption" className=" h-10 w-20 text-center content-center">
                          %
                        </Typography>                        
                      </div>
                    </div>
                    <Divider orientation="vertical" flexItem />
                    <div className="flex flex-col w-fit mt-1">
                      <Typography variant="subtitle2" className=" text-center content-center py-1.5">
                        Bugs
                      </Typography>
                      <Divider orientation="horizontal" flexItem />
                      <div className="flex w-fit">
                        <Typography variant="caption" className=" h-10 w-20 text-center content-center">
                          #
                        </Typography>  
                        <Divider orientation="vertical" flexItem />
                        <Typography variant="caption" className=" h-10 w-20 text-center content-center">
                          SPs
                        </Typography>  
                        <Divider orientation="vertical" flexItem />
                        <Typography variant="caption" className=" h-10 w-20 text-center content-center">
                          %
                        </Typography>                        
                    </div>                       
                    </div>
                    <Divider orientation="vertical" flexItem />
                    <div className="flex flex-col w-fit mt-1">
                    <Typography variant="caption" className=" h-20 w-20 text-center content-center">
                      Planned Capacity
                    </Typography>
                    </div>
                    <Divider orientation="vertical" flexItem />
                    <div className="flex flex-col w-fit mt-1">
                    <Typography variant="caption" className=" h-20 w-40 text-center content-center" sx={{ 'width': (isManagerView ? "12rem" : "10rem")}}>
                      Comments
                    </Typography>
                    </div>
                  </div>
                </div>
                <div className=" flex flex-col items-center justify-center px-3 bg-white rounded-md shadow-sm shadow-gray-300 py-1 hover:shadow-lg transition-all">
                  {
                    data.map((sprintStat, index) => {
                      try {
                        return (
                          <div
                            key={index}
                            className={`flex w-fit justify-center items-center h-9 rounded-md shadow-inner bg-gray-50 shadow-gray-100 my-1 transition-colors
                            ${
                              highlightedRowKey == sprintStat.sprint.sprint_id.toString()
                                ? 'bg-navman_green_grey_highlight shadow-none'
                                : 'bg-gray-50 shadow-inner'
                            }`}
                            onMouseEnter={() => {
                              setHighlightedRowKey(sprintStat.sprint.sprint_id.toString());
                            }}
                            onMouseLeave={() => {
                              setHighlightedRowKey('');
                            }}
                          >
                            <Typography variant="caption" className=" w-20 text-center">
                              {sprintStat.qa_story_points}
                            </Typography>
                            <Typography variant="caption" className=" w-20 text-center">
                              {sprintStat.sp_total}
                            </Typography>
                            <Typography variant="caption" className=" w-20 text-center">
                              {sprintStat.days_available}
                            </Typography>
                            <Typography variant="caption" className=" w-20 text-center">
                              {sprintStat.total_sp_per_day}
                            </Typography>
                            <Typography variant="caption" className=" w-20 text-center">
                              {sprintStat.completed_rate_of_committed_sps}
                            </Typography>
                            <Typography variant="caption" className=" w-20 text-center">
                              {sprintStat.support_counts}
                            </Typography>
                            <Typography variant="caption" className=" w-20 text-center">
                              {sprintStat.support_story_points}
                            </Typography>
                            <Typography variant="caption" className=" w-20 text-center">
                              {sprintStat.support_days}
                            </Typography>
                            <Typography variant="caption" className=" w-20 text-center">
                              {sprintStat.support_story_points_rate}
                            </Typography>
                            <Typography variant="caption" className=" w-20 text-center">
                              {sprintStat.bug_counts}
                            </Typography>
                            <Typography variant="caption" className=" w-20 text-center">
                              {sprintStat.bug_story_points}
                            </Typography>
                            <Typography variant="caption" className=" w-20 text-center">
                              {sprintStat.bug_story_points_rate}
                            </Typography>
                            <Typography variant="caption" className=" w-20 text-center">
                              {sprintStat.sp_committed}
                            </Typography>
                            <Typography variant="caption" className=" w-40 pl-2 text-left" sx={{'overflow': 'hidden'}}>
                              {sprintStat.comments}
                            </Typography>
                            { isManagerView &&
                              <Tooltip title={sprintStat.comments}>
                                <IconButton color="primary" size='small' onClick={() => onTeamSprintCommentClick(sprintStat)}>
                                    <FaPencil />
                                </IconButton>
                              </Tooltip>
                            }                          
                            </div>
                        );
                      } catch (error) {
                        return <div className="flex h-9 my-1"></div>;
                      }
                    })
                  }
                </div>
              </Stack>
            </div>
          </div>
          <div className={`spliter ${isTeamStatCollapsed ? 'collapsed' : ''}`} onClick={() => setTeamStatCollapsed(!isTeamStatCollapsed)}>
              <IconButton color="primary">
                { isTeamStatCollapsed ? <FaCaretRight /> : <FaCaretLeft /> }
              </IconButton>
          </div>          
          </div>  
          { isManagerView && teamSprintComment &&
            <Modal open={openTeamSprintCommentFormModal} onClose={handleSprintCommentClose}>
              <Box className="model-box">
                <Stack>
                  <Typography>Sprint Comment for {teamSprintComment.sprint_name}</Typography>
                  <TeamSprintCommentForm sprintComment={teamSprintComment} onSave={handleTeamSprintCommentSave} onClose={handleSprintCommentClose} />
                </Stack>
              </Box>
            </Modal>  
          }   
          </div>
      )}
    </>
  );
}

export default connect(loadProps)(TeamSprintsView);
