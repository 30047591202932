import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ViewInfo, ViewInfoState } from "../../models/view";

const initialState: ViewInfoState = {
    currentViewInfo: null,
    isManagerView: false
}

const viewInfoSlice = createSlice({
    name:'viewState',
    initialState,
    reducers: {
        updateCurrentViewInfo: (state,action:PayloadAction<ViewInfo|null>) => {
            state.currentViewInfo = action.payload
        },
        updateIsManagerView: (state, action:PayloadAction<boolean>) => {
            state.isManagerView = action.payload
        }
    }
})

export default viewInfoSlice.reducer
export const {updateCurrentViewInfo, updateIsManagerView} = viewInfoSlice.actions