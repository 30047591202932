import * as React from 'react';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import { close, selectSnackbar } from '../redux/slices/snackbarSlice';
import { useAppDispatch, useAppSelector } from '../redux/hooks';

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

/**
 * This component is a snackbar that displays a message to the user.
 * @returns the SnackBar component.
 */
export default function SnackBar() {
  const dispatch = useAppDispatch();
  const { open, message, severity } = useAppSelector(selectSnackbar);

  function handleClose() {
    dispatch(close());
  }

  return (
    <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
      <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
        {message}
      </Alert>
    </Snackbar>
  );
}
