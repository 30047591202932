import { cmtApi } from './apiSlice';

export interface UserGlobalPermissionUpdate {
    user_id: string;
    permission_level: string;
}

export const authApiSlice = cmtApi.injectEndpoints({
    endpoints: builder => ({
        getGlobalPermission: builder.query<number, void>({
            query: () => ({
                url: 'users/global_permission',
                method: 'GET',
            }),
            providesTags: ["GlobalPermission"]
        }),
        setGlobalPermission: builder.mutation<void, UserGlobalPermissionUpdate>({
            query: (data) => ({
                url: '/grant-permission/global',
                method: 'POST',
                body: data,
            }),
            invalidatesTags: ["GlobalPermission"]
        }),
    })
})



export const { useGetGlobalPermissionQuery, useLazyGetGlobalPermissionQuery, useSetGlobalPermissionMutation } = authApiSlice;