import { PermissionLevel } from "../models/permissionLevels";

const token_key = "jwt-token";
export function getJwtToken() {
    const token = localStorage.getItem(token_key);    
    //console.log('getting token:' + token?.substring(token?.length-15));
    return token;
}

export function setJwtToken(token: string) {
    localStorage.setItem(token_key, token);    
    //console.log('updating token:' + token.substring(token.length-15));
}

export function clearJwtToken() {
    localStorage.removeItem(token_key);
    //console.log('clear token');
}

export function hasJwtToken(): boolean {
    const token = localStorage.getItem(token_key);  
    return token !== undefined && token !== null && token.length > 0;
}

export function isAuthenticated(userPermission: number | undefined): boolean {
    return hasJwtToken() && userPermission !== undefined && userPermission > 0;
}

export function hasPermission(permissionLevel: PermissionLevel, userPermission: number | undefined): boolean {
    return hasJwtToken() && userPermission !== undefined && userPermission > 0 && ((permissionLevel & userPermission) === permissionLevel);
}

export function hasExactPermission(permissionLevel: PermissionLevel, userPermission: number | undefined): boolean {
    return hasJwtToken() && userPermission !== undefined && userPermission > 0 && (userPermission === permissionLevel);
}

export function hasAnyPermission(permissionLevels: PermissionLevel[], userPermission: number | undefined): boolean {
    if (permissionLevels.length <= 0)
        return hasJwtToken();
    return hasJwtToken() && userPermission !== undefined && userPermission > 0 && permissionLevels.find(p => (p & userPermission) === p) !== undefined;
}

export function hasAllPermissions(permissionLevels: PermissionLevel[], userPermission: number | undefined): boolean {
    if (permissionLevels.length <= 0)
        return hasJwtToken();
    if (hasJwtToken() && userPermission !== undefined && userPermission > 0) {
        permissionLevels.forEach(p =>
            {
                if ((p & userPermission) !== p) {
                    return false;
                } 
            }); 
            
        return true;           
    }
    return false;
}
