import React from 'react';
import Stack from '@mui/material/Stack';
import { Typography, Button } from '@mui/material';
import Paper from '@mui/material/Paper';
import { useLocation, useSearchParams, Navigate } from 'react-router-dom';
import { useEffect } from 'react';
import { clearJwtToken, setJwtToken } from '../utils/auth';
import { get_settings } from '../settings';

const BASE_URL = get_settings()['APP_API_URL'];
const jira_login_url = BASE_URL + "/jira/login?redirect=" + encodeURIComponent(window.location.origin + '/login');

const Login = () => {
  const [error, setError] = React.useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();
  const from = searchParams.get('redirect') || location.state?.from?.pathname || '/';
  const redirect_url = localStorage.getItem('login-redirect-url') || '/';
  //console.log(from);
  const token = searchParams.get('token');

  useEffect(() => {
    setError(false);
  }, [token]);

  if (token && token.length > 0) {
    // this is callback from jwt provider, we need to save the token and redirect to previous location
    setJwtToken(token);
  }
  else {
    localStorage.setItem('login-redirect-url', from);
    clearJwtToken();
  }

  if (token && token.length > 0) {
    return (
      <Navigate to={redirect_url} replace={true} />
    );
  } else {
    return (
      <Stack direction="row" sx={{ p: 4, height: '90vh' }} spacing={4} alignItems="center" justifyContent="center">
        <Paper elevation={3}>
          <img src="navman-logo.png" alt="Teletrac Navman Logo" width="400px" />
        </Paper>
        <Stack direction="column">
          <Typography variant="h4">CMT Login</Typography>
          <Button variant="contained" href={jira_login_url}>
            Login with JIRA
          </Button>
        </Stack>
      </Stack>
    );
  }
};

export default Login;
