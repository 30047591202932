import React from 'react';
import { CircularProgress, Stack, Typography } from '@mui/material';
import TeamSprintRowHeader from './TeamSprintRowHeader';
import { useAppSelector } from '../redux/hooks';
import { useGetUserSprintStatsQuery } from '../redux/slices/teamApiSlice';
import UserSprintUser from './UserSprintUser';
import UserSprintTeam from './UserSprintTeam';
import { Table, TableHead, TableBody, TableCell, TableRow } from '@mui/material';
import SingleUserSelect from '../controls/SingleUserSelect';
import { User, UserInTeam } from '../models/basic';
import { useGetPositionsQuery } from '../redux/slices/teamApiSlice';
import { connect } from 'react-redux';

interface ITeamSprintsProps {
  teamId: number;
}
const loadProps = (state: any) =>
{
  return {
    teamId: state.appState.currentTeam?.team_id,
  }
}
function UserSprintsView(props: ITeamSprintsProps) {
  const settingsChanged = useAppSelector((state) => state.appState.settingsChanged);
  const dataRefreshed = useAppSelector((state) => state.appState.dataRefreshed);
  const [highlightedRowKey, setHighlightedRowKey] = React.useState('');
  const [selectedUser, setSelectedUser] = React.useState<UserInTeam | null>(null);
  const { data: userStats, isError, refetch } = useGetUserSprintStatsQuery({teamId: props.teamId, userId: selectedUser?.id});
  const { data: allTeamUsers, error, isLoading } = useGetPositionsQuery(props.teamId);
  const [selectedUsersError] = React.useState(false);
  const [allTeamMembers, setAllTeamMembers] = React.useState([] as User[]);

  React.useEffect(() => {
    if (settingsChanged || dataRefreshed) {
      refetch();
    }
    setAllTeamMembers(allTeamUsers?.filter(u => u.role != 'Manager') || []);
    if (!userStats) {
      return;
    } else if (userStats.users.length <= 0) {
      return;
    }
  }, [allTeamUsers, userStats, settingsChanged, dataRefreshed]);

  return (
    <>
      {isError ? (
        <div className="flex items-center justify-center p-5 py-10">
          <Typography variant="h6" color={'error'}>
            Error Loading Stats
          </Typography>
        </div>
      ) : !userStats ? (
        <div className="flex items-center justify-center p-5">
          <CircularProgress size="10rem" />
        </div>
      ) : userStats.users.length === 0 ? (
        <div className=" w-full py-3 text-center">
            <SingleUserSelect
              selectedUser={selectedUser}
              selectedUserError={selectedUsersError}
              helperText={''}
              disabled={false}
              setSelectedUser={setSelectedUser}
              allUsers={allTeamMembers}
              isErrorGettingUsers={error}
              isLoadingUsers={isLoading}
              label="Select a User"
            />
        </div>
      ) : (
        <div>
          <div className=" w-full py-3 text-center">
              <SingleUserSelect
                selectedUser={selectedUser}
                selectedUserError={selectedUsersError}
                helperText={''}
                disabled={false}
                setSelectedUser={setSelectedUser}
                allUsers={allTeamMembers}
                isErrorGettingUsers={error}
                isLoadingUsers={isLoading}
                label="Select a User"
              />
            </div>
          <div className=" flex mb-10">
            <Stack direction={'column'} className=" min-w-fit overflow-hidden pr-3">
              <div className=" h-24 mt-1"></div>
              {userStats.sprints.map((sprint, index) => (
                <TeamSprintRowHeader
                  key={index}
                  title={sprint.sprint.sprint_name}
                  isClickable={false}
                  onClick={() => {}}
                  highlightedRowKey={highlightedRowKey}
                  setHighlightedRowKey={setHighlightedRowKey}
                  rowKey={sprint.sprint.sprint_id.toString()}
                />
              ))}

              <div className=" h-5"></div>
              <TeamSprintRowHeader
                title="SP per Day"
                isClickable={false}
                highlightedRowKey={highlightedRowKey}
                setHighlightedRowKey={setHighlightedRowKey}
                rowKey={'sp_per_day'}
              />
              <TeamSprintRowHeader
                title="% From Top Performer"
                isClickable={false}
                highlightedRowKey={highlightedRowKey}
                setHighlightedRowKey={setHighlightedRowKey}
                rowKey={'ratioed_by_top_performer'}
              />
              <TeamSprintRowHeader
                title="Last Sprint SP/Day"
                isClickable={false}
                highlightedRowKey={highlightedRowKey}
                setHighlightedRowKey={setHighlightedRowKey}
                rowKey={'last_sprint_sp_per_day'}
              />
              <TeamSprintRowHeader
                title="R^2 per Day"
                isClickable={false}
                highlightedRowKey={highlightedRowKey}
                setHighlightedRowKey={setHighlightedRowKey}
                rowKey={'difficulty_r2'}
              />
            </Stack>

            <div className=" flex-grow flex flex-row px-2 self-stretch shadow-inner shadow-slate-300 bg-gray-50 rounded-md overflow-x-auto border-2 border-gray-200">
              <UserSprintUser
                sprints={userStats.sprints.map((sprint) => sprint.sprint)}
                userSprintStats={userStats.users[0]}
                highlightedRowKey={highlightedRowKey}
                setHighlightedRowKey={setHighlightedRowKey}
              />
              <UserSprintTeam
                sprints={userStats.sprints.map((sprint) => sprint.sprint)}
                teamSprintStats={userStats.team}
                highlightedRowKey={highlightedRowKey}
                setHighlightedRowKey={setHighlightedRowKey}
              />
            </div>
          </div>

          <Typography variant="body1">Ticket Difficulty Breakdown</Typography>

          <div className=" w-full border-2 border-slate-100 rounded-md mt-2 overflow-hidden">
            <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Sprint</TableCell>
                    {
                      userStats.story_points.map((sp, idx) => (
                        <TableCell key={sp}>{sp}</TableCell>
                      ))
                    }
                  </TableRow>
                </TableHead>

                <TableBody>
                  {userStats.sprints.map((item, index) => (
                    <TableRow key={index}>
                      <TableCell>{item.sprint.sprint_name}</TableCell>
                      {
                        userStats.story_points.map((sp, idx) => (
                          <TableCell key={sp}>{item.story_points[sp]}</TableCell>
                        ))
                      }
                    </TableRow>
                  ))}
                  <TableRow key="total">
                    <TableCell>Total</TableCell>
                    {
                      userStats.story_points.map((sp, idx) => (
                        <TableCell key={sp}>{userStats.sprints.reduce((sum, sprint) => sum + sprint.story_points[sp], 0)}</TableCell>
                      ))
                    }
                  </TableRow>
                  <TableRow key="r2">
                    <TableCell>R^2</TableCell>
                    {
                      userStats.story_points.map((sp, idx) => (
                        <TableCell key={sp}>{(sp ** 2 * (userStats.sprints.reduce((sum, sprint) => sum + sprint.story_points[sp], 0))).toFixed(1)}</TableCell>
                      ))
                    }
                  </TableRow>
                </TableBody>
            </Table>
          </div>
        </div>
      )}
    </>
  );
}

export default connect(loadProps)(UserSprintsView);