import * as React from 'react';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import {
  DataGrid,
  GridRenderCellParams,
  GridColDef,
  GridCellParams,
  GridRowModes,
  MuiEvent,
  GridRowEditStopParams,
  useGridApiRef,
  GridRowModesModel
} from '@mui/x-data-grid';
import { Grid, Typography } from '@mui/material';
import MultipleUserSelect from '../controls/MultipleUserSelect';
import { useGetExperiencesQuery, useGetRolesQuery } from '../redux/slices/positionApiSlice';
import { UserInTeam } from '../models/basic';

interface IUserTableProps {
  selectedUsers: UserInTeam[];
  selectedUsersError: boolean;
  teamMembersAttributesError: boolean;
  setSelectedUsers: (selectedUsers: UserInTeam[]) => void;
  isHorizontal: boolean;
  rowModesModel: GridRowModesModel;
  setRowModesModel: (rowModesModel: GridRowModesModel) => void;
  selectableRoles: string[];
  lockRole: string | null;
}

/**
 * This component is a table of users used in the creation of a team.
 * @param props the props for this component.
 * @returns the UserTable component.
 */
export default function UserTable(props: IUserTableProps) {
  const {
    selectedUsersError,
    teamMembersAttributesError,
    selectedUsers,
    setSelectedUsers,
    rowModesModel,
    setRowModesModel
  } = props;
  const apiRef = useGridApiRef();
  const { data: roles } = useGetRolesQuery();
  const { data: experiences } = useGetExperiencesQuery();

  /**
   * If lockRole is not null, any new user added to the team will have that role.
   */
  React.useEffect(() => {
    if (props.lockRole) {
      props.selectedUsers.forEach((selectedUser) => {
        if (selectedUser.role !== 'Manager') {
          selectedUser.role = props.lockRole ? props.lockRole : '';
        }
      });
    }
  }, [props.selectedUsers, props.lockRole]);

  /**
   * Renders the select input cell for the DataGrid.
   * @param props the props for this component.
   * @returns the SelectEditInputCell component.
   */
  function SelectEditInputCell(props: GridRenderCellParams & { options: any[] }) {
    const { id, value, field, options } = props;

    const handleChange = async (event: SelectChangeEvent) => {
      await apiRef.current.setEditCellValue({
        id,
        field,
        value: event.target.value
      });
      apiRef.current.stopCellEditMode({ id, field });
      const user = selectedUsers.find((user) => user.id === id);
      if (user) {
        // update the user properties
        if (field === 'experience') {
          user.experience = event.target.value;
        } else if (field === 'role') {
          user.role = event.target.value;
        }
      }
      // set the selected users
      setSelectedUsers(selectedUsers);
    };

    return (
      <Select
        value={value === '' ? 'Select a value' : value}
        onChange={handleChange}
        fullWidth
        sx={{ height: 1 }}
        native
      >
        {options.map((option) => (
          <option key={option.value} value={option.label} hidden={option.value === 'Select a value' ? true : false}>
            {option.label}
          </option>
        ))}
      </Select>
    );
  }

  /**
   * Only true if cell is a experience or role (if not manager and no lockRole is in place)
   * @param params
   * @returns
   */
  function isCellEditable(params: GridCellParams) {
    if (params.field === 'role') {
      if (params.value !== 'Manager' && !props.lockRole) {
        return true;
      }
    } else if (params.field === 'experience') {
      return true;
    }

    return false;
  }

  React.useEffect(() => {
    const newRowModesModel: GridRowModesModel = {};
    selectedUsers.forEach((user) => {
      newRowModesModel[user.id] = { mode: GridRowModes.Edit };
    });
    setRowModesModel(newRowModesModel);
  }, [selectedUsers, setRowModesModel]);

  const renderSelectEditInputCellRole: GridColDef['renderCell'] = (params) => {
    const options = [{ value: '', label: 'Select a value' }];
    options.push(
      ...(roles
        ?.filter((role) => props.selectableRoles.includes(role.role_name))
        .map((role) => ({ value: role.role_name, label: role.role_name })) ?? [])
    );

    return (
      <SelectEditInputCell
        value={
          options.find((option) => option.value === selectedUsers.find((user) => user.id === params.id)?.role)?.value
        }
        {...params}
        options={options}
      />
    );
  };

  const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const renderSelectEditInputCellExperience: GridColDef['renderCell'] = (params) => {
    const options = [{ value: '', label: 'Select a value' }];
    options.push(
      ...(experiences?.map((experience) => ({
        value: experience.experience_name,
        label: experience.experience_name
      })) ?? [])
    );
    return <SelectEditInputCell {...params} options={options} />;
  };

  const columns = [
    {
      field: 'display_name',
      headerName: 'Display Name',
      flex: 1
    },
    {
      field: 'email',
      headerName: 'Email',
      type: 'email',
      flex: 1
    },
    {
      field: 'country',
      headerName: 'Country',
      flex: 1,
      valueGetter: (params: GridCellParams) => {
        return params.row.country_timezone.country_name;
      }
    },
    {
      field: 'experience',
      headerName: 'Experience',
      editable: true,
      flex: 1,
      renderEditCell: renderSelectEditInputCellExperience
    },
    {
      field: 'role',
      headerName: 'Role',
      editable: true,
      flex: 1,
      renderEditCell: renderSelectEditInputCellRole
    }
  ];

  return (
    <Grid container spacing={2} sx={{ width: '100%' }}>
      <Grid item xs={12}>
        <>
          <MultipleUserSelect selectedUsers={selectedUsers} setSelectedUsers={setSelectedUsers} />
          {selectedUsersError ? (
            <Typography variant="body1" color="error.main">
              You must select at least one user
            </Typography>
          ) : null}
          {teamMembersAttributesError ? (
            <Typography variant="body1" color="error.main">
              You must select a level and role for each team member
            </Typography>
          ) : null}
          <Grid item xs={12}>
            <DataGrid
              apiRef={apiRef}
              autoHeight
              sx={{ border: teamMembersAttributesError ? '2px solid' : 'none', borderColor: 'error.main' }}
              rows={selectedUsers}
              columns={columns}
              editMode="row"
              isCellEditable={isCellEditable}
              onRowEditStop={(params: GridRowEditStopParams, event: MuiEvent) => {
                event.defaultMuiPrevented = true;
              }}
              rowModesModel={rowModesModel}
              onRowModesModelChange={handleRowModesModelChange}
            />
          </Grid>
        </>
      </Grid>
    </Grid>
  );
}
