import * as React from 'react';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query'

/**
 * Type predicate to narrow an unknown error to `FetchBaseQueryError`
 */
export function isFetchBaseQueryError(
  error: unknown
): error is FetchBaseQueryError {
  return typeof error === 'object' && error != null && 'status' in error
}

/**
 * Type predicate to narrow an unknown error to an object with a string 'message' property
 */
export function isErrorWithMessage(
  error: unknown
): error is { message: string } {
  return (
    typeof error === 'object' &&
    error != null &&
    'message' in error &&
    typeof (error as any).message === 'string'
  )
}

export function updateListItem<IType>(setData: React.Dispatch<React.SetStateAction<IType[]>>, findKeyFunc: (a: IType) => boolean, funcUpdate: (item: IType) => any) {
  setData(prevData =>
    prevData.map(it => {
      if (findKeyFunc(it)) {
        return { ...it, ...(funcUpdate(it)) } as IType;
      }
      return it;
    })
  );  
}


export function updateObject<IType>(setData: React.Dispatch<React.SetStateAction<IType>>, funcUpdate: (item: IType) => any) {
  setData(prevData => ({
    ...prevData,
    ...(funcUpdate(prevData))
  } as IType));
}